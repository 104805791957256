import React from "react";
import BottomNav from "./navigation/BottomNavigation";
import { FaGreaterThan } from "react-icons/fa6";
import "../../assests/css/category.css";
import ExploreCourse from "./ExploreCourse";

const CategoryCourse = () => {
  const skills = [
    "Data Science",
    "AI/ML Daveloper",
    "Data Analytics",
    "Mobile Devloper",
    "Web Devloper",
  ];
  return (
    <div>
      <BottomNav />
      <div className="category_container">
        <div className="head_main">
          <div className="flex gap-2 md:flex-row flex-col md:items-center text-white">
            <div className="flex items-center gap-2">
              <div>Explore</div>
              <FaGreaterThan />
            </div>
            <div>Programming and Software Development</div>
          </div>
          <div className="cat_skills">Skills Your will Learn</div>
          <div className="btn_container">
            {skills.map((item, index) => {
              return (
                <button key={index} className="cat_btn md:p-2 p-1">
                  {item}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <ExploreCourse col1={2} col2={3} col3={4} />
    </div>
  );
};

export default CategoryCourse;
