import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userTypeAsync } from "../../../../store/features/AuthSlice";
import { FaCheck } from "react-icons/fa6";
const ChooseUserType = (props) => {
  const dispatch = useDispatch();
  const { handleSocialLogin, selectedOption, handleOptionChange } = props;

  useEffect(() => {
    dispatch(userTypeAsync());
  }, []);

  const userType = useSelector((state) => state.auth.userType);
  const filteredUserTypes =
    userType && userType.filter((userType) => userType.name !== "teacher");
  return (
    <div>
      <div>
        <h2 className="font-Roboto text-xl text-black mt-4">
          Select User Type:
        </h2>
        <div className="flex items-center flex-wrap mt-4">
          {filteredUserTypes &&
            filteredUserTypes.map((type, ind) => (
              <div key={ind} className="flex items-center mr-3 mb-2">
                <input
                  type="radio"
                  id={type?.name}
                  name={type?.name}
                  value={type?.name}
                  checked={selectedOption == type?.name}
                  onChange={() => handleOptionChange(type?.name, type?.id)}
                  className="hidden"
                />
                <label
                  htmlFor={type?.name}
                  className="h-4 w-4 rounded-full border border-gray-400 flex items-center justify-center cursor-pointer mr-2"
                >
                  {selectedOption == type?.name && (
                    <FaCheck className="text-[white] bg-blue-600 rounded-full" />
                  )}
                </label>
                <span className="text-gray-700 capitalize">{type?.name}</span>
              </div>
            ))}
        </div>
        <div>
          <button
            className="w-full mx-auto bg-blue-400 text-white py-1 my-2 rounded-md"
            onClick={() => handleSocialLogin()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseUserType;
