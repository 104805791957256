import React, { useState, useEffect } from "react";
import SecondaryButton from "../button/SecondaryButton";
import CardOne from "../card/CardOne";
import { getCourseListDashboard } from "../../store/authApi";

const NewTeacherCool = () => {
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    getCourseListDashboard().then((res) => setCourseData(res));
  }, []);
  console.log(courseData);
  const data = [
    { popular: "Popular" },
    { popular: "Programming and Software Development" },
    { popular: "Data Science and Analytics" },
    { popular: "Cybersecurity" },
    { popular: "Cloud Computing" },
    { popular: "Database" },
  ];
  const dataOne = [
    {
      img: "Media.png",
      title: "Web Development",
      about:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      btnText: "Beginner Course",
    },
    {
      img: "Media one.png",
      title: "Data Science and Machine Learning",
      about:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      btnText: "Beginner Course",
    },
    {
      img: "Media two.png",
      title: "Artificial Intelligence",
      about:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      btnText: "Beginner Course",
    },
    {
      img: "Media three.png",
      title: "Cyber Security",
      about:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      btnText: "Beginner Course",
    },
  ];
  return (
    <>
      <div className="container mx-auto py-10">
        <div>
          <h2 className="font-bold text-4xl sm:text-5xl px-4 sm:px-0">
            <span className="text-blue-500">New</span> on TeacherCool
          </h2>
          <p className="text-sm pt-3 text-gray-600 px-3 sm:px-0">
            Discover the latest updates on TeacherCool, the innovative Learning
            Management System. Empower your learning journey with tools designed
            for both educators and students.
          </p>

          <SecondaryButton data={data} />
          {courseData?.length > 0 ? (
            <CardOne dataOne={courseData} />
          ) : (
            <div
              div
              className="h-[10rem] w-full flex flex-col items-center justify-center"
            >
              <h2 className="text-2xl font-semibold text-gray-700 mb-2">
                Data not found!
              </h2>
              <p className="text-gray-500 mb-6">
                Sorry, we couldn’t find the data you’re looking for. Please try
                again later.
              </p>
            </div>
          )}
          <div className="pt-8 py-2 max-sm:pl-6 max-sm:py-2">
            <button className="bg-white text-blue-500 rounded-md text-base px-5 h-10 cursor-pointer border border-solid border-blue-400">
              View all courses <span>&raquo;</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTeacherCool;
