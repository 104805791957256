// refreshTokenSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { refreshTokenApi } from "../authApi";

const initialState = {
  token: null,
  status: null,
  errorMsg: null,
  loading: false,
};

export const refreshTokenAsync = createAsyncThunk(
  "refreshToken/refreshToken",
  async (data, { rejectWithValue }) => {
    try {
      const response = await refreshTokenApi(data);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const refreshTokenSlice = createSlice({
  name: "refreshToken",
  initialState,
  reducers: {
    clearErrorMsg: (state) => {
      state.errorMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshTokenAsync.pending, (state) => {
        state.status = "idle";
        state.loading = true;
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        state.status = "200";
        state.loading = false;
        state.errorMsg = null;
        state.token = action.payload;
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.loading = false;
        state.errorMsg = action.payload;
      });
  },
});

export const { clearErrorMsg } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
