import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { FiEdit2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Select } from "antd";
import {
  studentRegisterAsync,
  typeofCountryAsync,
  userProfileUpdateAsync,
} from "../../../../store/features/AuthSlice";

const StudentFinalSignup = (props) => {
  const fileRef = useRef();

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const dispatch = useDispatch();
  const { userType, student, countrylist } = useSelector((state) => state.auth);
  const {
    studentFormValues,
    setStudentFormValues,
    selectedOption,
    setShowEmailToast,
    NavigateOnLogin,
  } = props;

  const userTypeId = userType.find((item) => item.name === selectedOption)?.id;

  useEffect(() => {
    dispatch(typeofCountryAsync());
  }, []);

  const onChange = (value) => {
    setStudentFormValues((prevState) => ({
      ...prevState,
      country: value,
    }));
  };

  const registerStudent = async (event) => {
    event.preventDefault();
    const data = {
      type: userTypeId,
      name: studentFormValues.studentName,
      email: studentFormValues.studentEmail,
      mobile: studentFormValues.studentNumber.slice(2, 12),
      password: studentFormValues.studentPassword,
      confirm_password: studentFormValues.studentPassword,
      country: studentFormValues.country,
      referralCode: studentFormValues.referralCode,
      profile: {
        image: studentFormValues.profileImage,
      },
    };
    if (
      data &&
      studentFormValues.country !== "" &&
      studentFormValues.referralCode !== ""
    ) {
      dispatch(studentRegisterAsync(data));
      NavigateOnLogin();
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    if (name === "profileImage" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setStudentFormValues((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setStudentFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleButtonClick = () => {
    fileRef.current.click();
  };
  return (
    <React.Fragment>
      <input
        type="file"
        ref={fileRef}
        accept="image/*"
        name="profileImage"
        // value={sendImage}
        className="hidden"
        onChange={handleChange}
      />
      <div className="mt-6">
        <form onSubmit={registerStudent}>
          <div className="flex items-center justify-around gap-5">
            <div className="relative w-[159px] h-[156px] rounded-full border-2">
              <label onClick={handleButtonClick}>
                <p className="absolute z-10  right-3 m-2 bg-[white] cursor-pointer">
                  <FiEdit2 className="text-gray-400 bg-[white]" />
                </p>
              </label>
              <div className="absolute text-sm text-gray-600 text-wrap text-center bottom-12">
                {studentFormValues.profileImage ? (
                  <img
                    src={studentFormValues.profileImage}
                    className="relative top-12 left-0 border-none rounded-full w-[155px] h-[155px]"
                    alt="no image"
                  />
                ) : (
                  " Image size should be 150x200"
                )}
              </div>
            </div>
            <div onClick={handleButtonClick} className="cursor-pointer">
              <p className="text-sm text-gray-900 font-semibold">
                Upload Picture
              </p>
              <span className="text-gray-600 text-sm">(Non Mandatory)</span>
            </div>
          </div>
          <div className="mb-4 mt-6">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Country
            </label>

            <Select
              showSearch
              className="w-full"
              // style={{ width: "300px" }}
              placeholder="Select a Country"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={((countrylist && countrylist) || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />

            {studentFormValues.country === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>

          <div className="mb-4 mt-1">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Referal Code
            </label>
            <input
              type="text"
              name="referralCode"
              value={studentFormValues.referralCode}
              onChange={handleChange}
              className="border bg-[white] text-gray-600 text-sm border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="Enter Referal Code"
            />
            {studentFormValues.referralCode === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>

          <div className="flex items-center justify-center">
            <input
              type="checkbox"
              name="agree"
              value={studentFormValues.agree}
              onChange={handleChange}
            />
            <p className="text-gray-600 text-[12px] pl-2">
              I agree to the TeacherCool{" "}
              <span className="text-blue-600 text-[12px]">
                Privacy policies & Terms
              </span>
            </p>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-7 rounded"
          >
            Join for Free
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};

export default StudentFinalSignup;
