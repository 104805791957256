import React, { useState, useEffect } from "react";
import { RiArrowDropDownFill } from "react-icons/ri";
import { createUserRequest, getCourseListDashboard } from "../../store/authApi";
import { typeofCountryAsync } from "../../store/features/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

export const ExpertLeft = () => {
  const dispatch = useDispatch();
  const { countrylist } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    describesYouBest: "",
    selectCourse: "",
    subject: "",
    email: "",
    country: "India", // Default value
    phone: "",
    message: "",
  });
  const [courseData, setCourseData] = useState([]);
  console.log("courseData", courseData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getCourseListDashboard().then((res) => setCourseData(res));
  }, []);
  useEffect(() => {
    dispatch(typeofCountryAsync());
  }, [dispatch]);

  const handleSubmit = () => {
    const data = {
      type: formData.describesYouBest,
      subject: formData.subject,
      email: formData.email,
      phone_number: formData.phone,
      country: formData.country,
      message: formData.message,
      course: formData.selectCourse,
    };
    createUserRequest(data);
  };

  return (
    <>
      <div className="expertLeft">
        <h1 className="text-black text-5xl font-bold mb-3">Get Expert Help</h1>
        <p className="font-medium text-gray-500 py-2 font-sans text-lg">
          If you have any questions or request for any course, please fill in
          the form below and we will get back to you as soon as possible.{" "}
        </p>
      </div>
      {/* <Tabs tabs={expertabs} /> */}
      <div className="">
        <div>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
              <div>
                <form className="bg-white px-1 pt-6 pb-8 mb-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="relative mb-4">
                      <select
                        name="describesYouBest"
                        value={formData.describesYouBest}
                        onChange={handleChange}
                        className="appearance-none w-full bg-white border-2 border-gray-300 rounded-md pl-2 pr-16 py-3 px-3 focus:outline-none focus:border-blue-500"
                      >
                        <option value="">What describes you best</option>
                        <option value="Individuals">Individuals</option>
                        <option value="Corporates">Corporates</option>
                        <option value="University">University</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 bottom-1 flex items-center px-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 fill-current text-gray-500"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.293 14.293a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L10 11.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4z" />
                        </svg>
                      </div>
                    </div>
                    <div className="relative mb-4">
                      <select
                        name="selectCourse"
                        value={formData.selectCourse}
                        onChange={handleChange}
                        className="appearance-none w-full bg-white border-2 border-gray-300 rounded-md pl-2 pr-16 py-3 px-3 focus:outline-none focus:border-blue-500"
                      >
                        <option value="">Select Courses</option>
                        {courseData?.map((item, index) => {
                          return (
                            <option key={index} value={item?.id}>
                              {item?.title}
                            </option>
                          );
                        })}
                      </select>
                      <div className="absolute inset-y-0 right-0 bottom-1 flex items-center px-2 pointer-events-none">
                        <svg
                          className="w-5 h-5 fill-current text-gray-500"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.293 14.293a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L10 11.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4z" />
                        </svg>
                      </div>
                    </div>
                    <div className="mb-4">
                      <input
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none"
                        id="subject"
                        type="text"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none"
                        id="email"
                        type="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="mb-4 relative">
                      <select
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none bg-white"
                        id="country"
                      >
                        {countrylist?.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                        <RiArrowDropDownFill size={34} />
                      </div>
                    </div>
                    <div className="mb-4">
                      <input
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none"
                        id="phone"
                        type="text"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none"
                      rows="5"
                      id="message"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Get Quote
                    </button>
                  </div>
                </form>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Experts tab
const expertabs = [
  {
    content: (
      <div>
        <div className="">
          <div>
            <div className="container mx-auto ">
              <div className="grid grid-cols-1 lg:grid-cols-1  gap-4">
                <div>
                  <form className="bg-white  px-1 pt-6 pb-8 mb-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="mb-4">
                        <input
                          className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none "
                          id="subject"
                          type="text"
                          placeholder="Subject"
                        />
                      </div>
                      <div className="mb-4">
                        <input
                          className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none "
                          id="email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>

                      <div className="mb-4 relative">
                        <select
                          className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none  bg-white"
                          id="country"
                        >
                          <option>India</option>
                          <option>Canada</option>
                          <option>America 3</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                          <RiArrowDropDownFill size={34} />
                        </div>
                      </div>

                      <div className="mb-4">
                        <input
                          className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none "
                          id="phone"
                          type="text"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="appearance-none border-[2px] border-gray-300 rounded w-full py-3 px-3 text-gray-800 leading-tight focus:outline-none"
                        rows="5"
                        id="message"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="flex items-center justify-between">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none "
                        type="button"
                      >
                        Get Quote
                      </button>
                    </div>
                  </form>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
  },
  // {
  //   id: "3",
  //   title: "Web Development",
  //   content: <div>{/* Content for Data Science and Analytics tab */}</div>,
  // },
  // {
  //   id: "2",
  //   title: "Internet of Things (IoT)",
  //   content: <div>Content for Internet of Things (IoT) tab</div>,
  // },
  // {
  //   id: "5",
  //   title: "Data Science and Analytics",
  //   content: <div>Content for Data Science and Analytics tab</div>,
  // },

  // {
  //   id: "4",
  //   title: "Others..",
  //   content: <div>Content for Data Science and Analytics tab</div>,
  // },
];

export const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="flex flex-col mt-3">
      {/* <ul className="flex mb-3 flex-wrap max-sm:justify-center gap-4">
        {tabs.map((tab) => (
          <li key={tab.id} className="mr-1">
            <button
              className={`${
                activeTab === tab.id
                  ? "bg-blue-600  border-blue-600 text-white"
                  : "bg-white"
              } text-gray-700 px-4 py-2 rounded-full hover:bg-blue-500 hover:border-blue-500 hover:text-white  border-gray-300 border-[2px] font-medium max-sm:w-[280px]`}
              onClick={() => handleTabClick(tab.id)}
              aria-selected={activeTab === tab.id ? "true" : "false"}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul> */}

      {/* TabContent */}
      <div className="">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${activeTab === tab.id ? "block" : "hidden"}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
