import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAddCourse: false,
  addCourseContentModal: false,
  contentModal: false,
};

const adminDashboardSlice = createSlice({
  name: "admindashboard",
  initialState,
  reducers: {
    setShowAddCourse: (state, action) => {
      state.showAddCourse = action.payload;
    },
    createCourseContentData: (state, action) => {
      state.addCourseContentModal = action.payload;
    },
    showAddContentModal: (state, action) => {
      state.contentModal = action.payload;
    },
  },
});
export const {
  setShowAddCourse,
  createCourseContentData,
  showAddContentModal,
} = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;
