import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../assests/css/navigation.css";
import Logo from "../../../assests/images/Logo.png";
import downArrow from "../../../assests/images/downArrow.png";
import search from "../../../assests/images/search.png";
import JoinFreeBtn from "../../button/PrimaryButton";
import Navigation from "./TopNavigation";
import Login from "../login/Login";
import Signup from "../signup/Signup";
import { NavLink } from "react-router-dom";
import { RoutePaths } from "../../../route/RoutePath";
import { toast } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import { Sidebar } from "../../Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import {
  changeShowLogin,
  changeCredential,
} from "../../../store/features/linkedinLoginSlice";
import { FaGreaterThan } from "react-icons/fa6";
const subjectData = [
  "Data Science",
  "Business",
  "Computer Science",
  "Information Technology",
  "Web Development",
  "Web Designing",
  "Digital Marketing",
  "Graphic designing",
];

const BottomNav = () => {
  const [showInstructorJoin, setShowInstructorJoin] = useState(false);
  const [forgetPassword, setForgetPassword] = useState("login");
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isCrossIcon, setIsCrossIcon] = useState(false);
  const [showExplore, setShowExplore] = useState(false);
  const { showLoginModal, signupModal } = useSelector(
    (state) => state.linkedinLogin
  );
  const baseUrl = process.env.REACT_APP_BASEURL;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setShowSidebar(!showSidebar);
    setIsCrossIcon(!isCrossIcon);
  };

  const [isInputVisible, setInputVisible] = useState(false);

  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleLoginClick = () => {
    dispatch(changeShowLogin(true));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ---------------login with linkedin------------------
  useEffect(() => {
    const fetchAccessToken = async (code) => {
      try {
        const response = await axios.post(
          `${baseUrl}user/linkedin/user_link/`,

          {
            code: code,
          }
        );
        const accessData = response.data;

        toast.success(response?.data?.message);
        if (response?.data?.access) {
          localStorage.setItem(
            "userAuth",
            JSON.stringify(response?.data?.access)
          );
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );
        }
        if (response?.data?.refresh) {
          localStorage.setItem(
            "refreshtoken",
            JSON.stringify(response?.data?.refresh)
          );
        }

        if (accessData) {
          if (!accessData.hasOwnProperty("user_type")) {
            dispatch(changeShowLogin(true));
            dispatch(changeCredential(true));
          } else {
            dispatch(changeCredential(false));
          }

          if (accessData.user_type === "student") {
            navigate("/student/dashboard");
          } else if (accessData.user_type === "admin") {
            navigate("/admin/dashboard");
          } else if (accessData.user_type === "corporate") {
            navigate("/corporate/dashboard");
          } else if (accessData.user_type === "teacher") {
            navigate("/teacher/dashboard");
          } else {
            navigate("/");
          }
        }
        // Use the access token to fetch user data
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    console.log("code", code);
    if (code) {
      fetchAccessToken(code);
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className="flex justify-between items-center max-sm:p-4 sm:p-4 lg:p-0">
        <div className="lg:hidden items-center">
          <button
            className={`text-${scrolling ? "black" : "black"}`}
            onClick={handleClick}
          >
            {isCrossIcon ? (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-12 h-10"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-12 h-10"
              >
                <path d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            )}
          </button>
        </div>
        {/* Sidebar logo  */}
        <div>
          <NavLink
            to={RoutePaths.home.path}
            className="logo_with_btn lg:hidden"
          >
            <img src={Logo} alt="nav-logo" className="cursor-pointer" />
          </NavLink>
        </div>
        <div className="search-field flex flex-row lg:hidden ">
          <input
            className={`outline-none border rounded-full  w-[40px] ${
              isInputVisible ? "w-20 px-4" : "w-0"
            } transition-width duration-500 ease-in-out`}
            type="text"
            style={{ display: isInputVisible ? "block" : "none" }}
          />
          <span
            className="h-[40px] block pt-2.5 rounded-md text-gray-500 font-thin	 text-xl cursor-pointer relative right-7"
            onClick={toggleInputVisibility}
          >
            <FaSearch />
          </span>
        </div>
      </div>

      {/* Sidebar */}
      {showSidebar && (
        <LeftTransition>
          <Sidebar />
        </LeftTransition>
      )}

      {/* Main Content */}
      {windowWidth >= 1024 && (
        <div className="bottom_head_nav py-4 ">
          <div className=" container mx-auto">
            <div className="flex items-center justify-between">
              <NavLink to={RoutePaths?.home?.path}>
                <img src={Logo} alt="nav-logo" className="cursor-pointer" />
              </NavLink>

              <div className="ml-[10px] relative  lg:block ">
                <div
                  onClick={() => setShowExplore(!showExplore)}
                  className="font-semibold block cursor-pointer appearance-none bg-customBlue text-bgWhite px-2 py-3 pr-10 rounded shadow leading-tight focus:outline-none focus:border-blue-500 w-21"
                >
                  Explore
                </div>
                {showExplore && (
                  <div className="absolute w-[16rem] p-3 gap-4 top-[3rem] bg-white shadow-sm  rounded shadow-white left-0 flex flex-col">
                    <div className="flex flex-col gap-2">
                      <div className="font-bold">Goals</div>
                      <NavLink
                        to="/courses/free"
                        className="ps-3 cursor-pointer hover:bg-blue-300 hover:text-white"
                      >
                        Take a free course
                      </NavLink>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="font-bold">Subjects</div>
                      {subjectData.map((item, index) => {
                        return (
                          <NavLink
                            key={index}
                            to=""
                            className="cursor-pointer hover:bg-blue-300 hover:text-white flex justify-between items-center"
                          >
                            <div className="ps-3">{item}</div>
                            <span className="text-[10px]">
                              <FaGreaterThan />
                            </span>
                          </NavLink>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-2 text-gray-700">
                  <img src={downArrow} alt="downarrow" />
                </div>
              </div>

              {/* Search Field */}
              <div className="flex border rounded-md border-[#c0c0c0]">
                <input
                  className="px-[10px] outline-none"
                  type="text"
                  placeholder="What do you want to learn today?"
                />
                <span className="img_search_icon">
                  <img src={search} alt="search-image" />
                </span>
              </div>

              {/* Navlinks */}
              <div>
                <Navigation />
              </div>

              {/* Navbtn */}
              <div className="btns_nav">
                <button onClick={handleLoginClick} className="login_btn_nav">
                  {localStorage.getItem("user_type") != null ||
                  localStorage.getItem("user_type") != undefined
                    ? "Logout"
                    : "Login"}
                </button>
                <JoinFreeBtn
                  title={"Join for Free"}
                  bgColor={"bg-blue-600"}
                  color={"text-white"}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Login Modal part*/}
      {showLoginModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50">
          <Login
            // setShowSignUpPage={setShowSignUpPage}
            setForgetPassword={setForgetPassword}
            forgetPassword={forgetPassword}
          />
        </div>
      )}

      {/* Sign Up Page */}
      {signupModal && (
        <div className="fixed inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50">
          <Signup
            // setShowSignUpPage={setShowSignUpPage}
            // showSignUpPage={showSignUpPage}
            showInstructorJoin={showInstructorJoin}
            setShowInstructorJoin={setShowInstructorJoin}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default BottomNav;

export const LeftTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ x: -10, opacity: 0 }} // initial position and opacity
      animate={{ x: 0, opacity: 1 }} // animate to this position and opacity
      exit={{ x: -50, opacity: 0 }} // animate out to this position and opacity
      transition={{ type: "tween", duration: 0.05 }} // animation transition type and duration
    >
      {children}
    </motion.div>
  );
};
