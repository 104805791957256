import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/AuthSlice";
import refreshTokenSlice from "./features/refreshTokenSlice";
import linkedinLoginSlice from "./features/linkedinLoginSlice";
import adminDashboardSlice from "./features/adminDashboardSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    refreshToken: refreshTokenSlice,
    linkedinLogin: linkedinLoginSlice,
    adminDashboard: adminDashboardSlice,
  },
});
