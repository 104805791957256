export const RoutePaths = {
  home: {
    path: "/",
    name: "Home",
  },

  forIndividuals: {
    path: "/forIndividuals",
    name: "forIndividuals",
  },

  forUniversities: {
    path: "/universities",
    name: "forUniversities",
  },
  forCorporates: {
    path: "/corporate",
    name: "forCorporate",
  },
};
