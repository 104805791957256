import React, { useState, useEffect } from "react";
import { FaRegEye } from "react-icons/fa6";
import { IoEyeOffOutline } from "react-icons/io5";
import validator from "validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const UniversitySignup = (props) => {
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [nextMove, setNextMove] = useState(false);

  const {
    nextUniversityPage,
    universityFormvalues,
    setUniversityFormValues,
    hideAndShowPassword,
    showPassword,
  } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const userName = /^[A-Za-z ]*$/;
    if (value === "" || userName.test(value)) {
      setUniversityFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setUniversityFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value === "" || emailPattern.test(value)) {
      setNextMove(true);
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    } else {
      setNextMove(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;
    setUniversityFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (passwordPattern.test(value)) {
      setNextMove(true);
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    } else {
      setNextMove(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must have at least 6 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)",
      }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const { name, value } = e.target;
    setUniversityFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value === universityFormvalues.universityPassword) {
      setNextMove(true);
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    } else {
      setNextMove(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
    }
  };

  const handleAlert = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (
      errors.email === "" &&
      errors.password === "" &&
      errors.confirmPassword === ""
    ) {
      setNextMove(true);
    } else {
      setNextMove(false);
    }
  }, [errors.email, errors.password && errors.confirmPassword === ""]);

  return (
    <div>
      <form>
        <div className="mb-4 mt-4">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Full Name
          </label>
          <input
            type="text"
            name="universityName"
            value={universityFormvalues.universityName}
            onChange={handleChange}
            className="border bg-[white] border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="Enter university name"
          />
          {universityFormvalues.universityName === "" && (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          )}
        </div>

        <div className="mb-4 mt-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Email Address
          </label>
          <input
            type="email"
            name="universityEmail"
            value={universityFormvalues.universityEmail}
            onChange={handleEmailChange}
            className="border bg-[white] border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="name@gmail.com"
          />
          {universityFormvalues.universityEmail === "" ? (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          ) : (
            <span
              className={`block text-sm  ${
                errors == "" ? "" : "text-red-600 pt-1"
              }`}
            >
              {errors.email}
            </span>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Phone Number
          </label>
          <PhoneInput
            country="in"
            name="universityNumber"
            value={universityFormvalues.universityNumber}
            onChange={(phone) =>
              setUniversityFormValues({
                ...universityFormvalues,
                universityNumber: phone,
              })
            }
            countryCodeEditable={false}
            placeholder="ph number"
            onlyCountries={["in", "us", "au"]}
            inputStyle={{ width: "100%", height: "42px", paddingLeft: "42px" }}
            dropdownStyle={{ maxWidth: "250px" }}
          />
          {universityFormvalues.universityNumber.length < 12 && (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          )}
        </div>

        <div className="mb-4 ">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Password
          </label>
          <div className=" relative">
            <input
              type={showPassword?.password === "password" ? "text" : "password"}
              name="universityPassword"
              value={universityFormvalues.universityPassword}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="Create password"
            />
            <span
              onClick={() => hideAndShowPassword("password")}
              className="absolute  right-4 bottom-3 cursor-pointer"
            >
              {showPassword?.password === "password" ? (
                <FaRegEye />
              ) : (
                <IoEyeOffOutline />
              )}
            </span>
          </div>
          {universityFormvalues.universityPassword === "" ? (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          ) : (
            <span
              className={`block text-sm  ${
                errors == "" ? "" : "text-red-600 pt-1"
              }`}
            >
              {errors.password}
            </span>
          )}
        </div>

        <div className="mb-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Confirm Password
          </label>
          <div className=" relative">
            <input
              type={
                showPassword?.confirmPassword === "confirmPassword"
                  ? "text"
                  : "password"
              }
              name="confirmPassword"
              autoComplete="current-password"
              value={universityFormvalues.confirmPassword}
              onChange={handleConfirmPasswordChange}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              placeholder="Create password"
            />
            <span
              onClick={() => hideAndShowPassword("confirmPassword")}
              className="absolute  right-4 bottom-3 cursor-pointer"
            >
              {showPassword?.confirmPassword === "confirmPassword" ? (
                <FaRegEye />
              ) : (
                <IoEyeOffOutline />
              )}
            </span>
          </div>
          {universityFormvalues.confirmPassword !==
          universityFormvalues.universityPassword ? (
            <span className="block text-sm text-red-500 pt-1">
              password should be match !
            </span>
          ) : (
            universityFormvalues.confirmPassword === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )
          )}
        </div>

        {nextMove ? (
          <button
            onClick={nextUniversityPage}
            className="bg-blue-500 hover:bg-blue-600 w-[100%] text-white font-semibold py-2 px-4 mt-7 rounded"
          >
            <span className="pr-2">Next </span> <span>&raquo;</span>
          </button>
        ) : (
          <button
            onClick={(e) => handleAlert(e)}
            className="bg-blue-500 hover:bg-blue-600 w-[100%] text-white font-semibold py-2 px-4 mt-7 rounded"
          >
            <span className="pr-2">Next </span> <span>&raquo;</span>
          </button>
        )}
      </form>
      <div>
        <div className="mb-4 mt-7 flex">
          <hr className="border-gray-300 my-4 inline-block w-1/2" />
          <span className="text-gray-600 mx-3 my-1">or</span>
          <hr className="border-gray-300 my-4 inline-block w-1/2" />
        </div>
      </div>
    </div>
  );
};

export default UniversitySignup;
