import React from "react";
import { MdErrorOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMsg } from "../../../store/features/AuthSlice";
const ErrorMessage = () => {
  const { errorMsg } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleCancelBtn = () => {
    dispatch(clearErrorMsg());
  };
  return (
    <div className="w-fit z-10 absolute top-[12%] right-[1%] bg-gradient-to-b from-red-400 to-red-600 text-white text-2xl flex gap-4 justify-between flex-row-reverse p-3 rounded-md">
      <span onClick={handleCancelBtn} className="cursor-pointer">
        &times;
      </span>
      <span className="flex items-center gap-3">
        <MdErrorOutline />
        <h3>{errorMsg?.email ? errorMsg.email[0] : errorMsg}</h3>
      </span>
    </div>
  );
};

export default ErrorMessage;
