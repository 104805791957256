// import React, { useState } from "react";

// const LabCard = () => {
//   const [activeCard, setActiveCard] = useState(null);
//   const cardTitle = [
//     {
//       img: "webIcon",
//       title: "React JS Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//     {
//       img: "ibm",
//       title: "IBM React Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//     {
//       img: "ibm",
//       title: "IBM React Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//     {
//       img: "ibm",
//       title: "IBM React Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//     {
//       img: "ibm",
//       title: "IBM React Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//     {
//       img: "ibm",
//       title: "IBM React Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//     {
//       img: "ibm",
//       title: "IBM React Lab",
//       para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     },
//   ];
//   return (
//     <div className="">
//       <div className="grid grid-cols-5 gap-4 ">
//         {cardTitle?.length > 0 &&
//           cardTitle?.map((item, ind) => {
//             return (
//               <div
//                 key={ind}
//                 className="border border-[#E1E1E1] rounded-md flex flex-col px-3 py-4 transition duration-700 hover:scale-110 items-start shadow-md "
//               >
//                 <img
//                   src={
//                     process.env.PUBLIC_URL + `/images/student/${item.img}.png`
//                   }
//                 />
//                 <p className="text-base leading-4 font-Roboto font-semibold text-[#1E1E1E] text-opacity-95 py-2">
//                   {item.title}
//                 </p>
//                 <p className="text-[10px] font-Roboto text-[#696969]  pb-0">
//                   {item?.para}
//                 </p>

//                 <div className="mx-auto mt-6 mb-0">
//                   <button
//                     className={`flex items-center justify-center text-center gap-2 ${
//                       activeCard === ind
//                         ? "bg-[#0966ED]  text-white"
//                         : "text-[#0966ED] border border-[#0966ED] rounded-md "
//                     }  w-[167px] h-[37px] rounded-md font-Roboto text-sm `}
//                     onClick={() => setActiveCard(ind)}
//                   >
//                     {activeCard === ind ? "Start Learning" : "Explore Labs"}
//                     <img
//                       className=""
//                       src={
//                         activeCard === ind
//                           ? process.env.PUBLIC_URL +
//                             "/images/student/sendWhite.png"
//                           : process.env.PUBLIC_URL +
//                             "/images/student/sendBlue.png"
//                       }
//                       alt="img"
//                     />
//                   </button>
//                 </div>
//               </div>
//             );
//           })}
//       </div>
//     </div>
//   );
// };

// export default LabCard;

import React, { useState } from "react";

const LabCard = () => {
  const [activeCard, setActiveCard] = useState(null);
  const cardTitle = [
    {
      img: "webIcon",
      title: "React JS Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: "ibm",
      title: "IBM React Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: "ibm",
      title: "IBM React Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: "ibm",
      title: "IBM React Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: "ibm",
      title: "IBM React Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: "ibm",
      title: "IBM React Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      img: "ibm",
      title: "IBM React Lab",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {cardTitle?.length > 0 &&
          cardTitle.map((item, ind) => {
            return (
              <div
                key={ind}
                className="border border-[#E1E1E1] rounded-md flex flex-col px-3 py-4 transition duration-700 hover:scale-105 items-start shadow-md"
              >
                <img
                  src={
                    process.env.PUBLIC_URL + `/images/student/${item.img}.png`
                  }
                  alt={`${item.title} logo`}
                  className="w-20 h-20 mb-4"
                />
                <p className="text-base leading-4 font-Roboto font-semibold text-[#1E1E1E] text-opacity-95 py-2">
                  {item.title}
                </p>
                <p className="text-[12px] font-Roboto text-[#696969]">
                  {item.para}
                </p>
                <div className="mt-6">
                  <button
                    className={`flex items-center px-2 justify-center gap-2 ${
                      activeCard === ind
                        ? "bg-[#0966ED] text-white"
                        : "text-[#0966ED] border border-[#0966ED] rounded-md"
                    } w-full h-[37px] rounded-md font-Roboto text-sm`}
                    onClick={() => setActiveCard(ind)}
                  >
                    {activeCard === ind ? "Start Learning" : "Explore Labs"}
                    <img
                      src={
                        activeCard === ind
                          ? process.env.PUBLIC_URL +
                            "/images/student/sendWhite.png"
                          : process.env.PUBLIC_URL +
                            "/images/student/sendBlue.png"
                      }
                      alt="icon"
                      className="w-[14px] h-[14px]"
                    />
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LabCard;
