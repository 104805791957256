import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RoutePaths } from "./route/RoutePath";
import { Individual } from "./components/pages/Individuals/Individual";
import { ToastContainer } from "react-toastify";
import TokenRefresh from "./components/TokenRefresh";
import LinkdinLogin from "./components/pages/login/socialSignupTeacher/LinkdinLogin";
import CategoryCourse from "./components/pages/CategoryCourse";

const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

const HeroExplore = React.lazy(() => import("./components/pages/HeroExplore"));

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <ToastContainer
          limit={1}
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          type={"type"}
        />
        <Suspense>
          {/* <TokenRefresh /> */}

          <Routes>
            <Route
              exact
              path="/"
              name={RoutePaths.home.name}
              element={<Individual />}
            />
            <Route
              exact
              path="/courses/free"
              name="Explore"
              element={<HeroExplore />}
            />
            <Route
              exact
              path="/courses"
              name="courses"
              element={<CategoryCourse />}
            />
            <Route
              exact
              path={`/linkedin/:id`}
              name="linkdin"
              element={<LinkdinLogin />}
            />
            <Route
              exact
              path="*"
              name="forIndividuals"
              element={<DefaultLayout />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
