import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  credential: false,
  showLoginModal: false,
  signupModal: false,
};

const linkedinLoginSlice = createSlice({
  name: "linkedin",
  initialState,
  reducers: {
    changeCredential: (state, action) => {
      state.credential = action.payload;
    },
    changeShowLogin: (state, action) => {
      state.showLoginModal = action.payload;
    },
    showSignupModal: (state, action) => {
      state.signupModal = action.payload;
    },
  },
});
export const { changeCredential, changeShowLogin, showSignupModal } =
  linkedinLoginSlice.actions;
export default linkedinLoginSlice.reducer;
