import React, { useState, useEffect, useRef } from "react";
import { FiEdit2 } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa6";
import validator from "validator";
import { IoEyeOffOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import { typeofCountryAsync } from "../../../../store/features/AuthSlice";

const InstructorMiddleSignup = (props) => {
  const fileRef = useRef(null); // Create the ref
  const [errors, setErrors] = useState({});
  const [nextMove, setNextMove] = useState(false);
  const { countrylist } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    nextInstructorFinalPage,
    instructorSignup,
    setInstructorSignup,
    showPassword,
    setShowPassword,
    hideAndShowPassword,
  } = props;

  const validatePassword = (password) => {
    if (
      validator.isStrongPassword(password, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
      setNextMove(true);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must have at least 6 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)",
      }));
      setNextMove(false);
    }
  };

  useEffect(() => {
    dispatch(typeofCountryAsync());
  }, []);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChange = (value) => {
    setInstructorSignup((prevState) => ({
      ...prevState,
      country: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setInstructorSignup((prevState) => ({
          ...prevState,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setInstructorSignup((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (name === "password") {
      validatePassword(value);
    }
  };
  useEffect(() => {
    if (
      instructorSignup?.password !== "" &&
      instructorSignup?.password !== undefined
    )
      validatePassword(instructorSignup?.password);
  }, [instructorSignup?.password]);
  useEffect(() => {
    if (errors.password === "") {
      setNextMove(true);
    } else {
      setNextMove(false);
    }
  }, [errors.password]);

  const handleButtonClick = () => {
    fileRef.current.click();
  };

  return (
    <React.Fragment>
      <input
        type="file"
        accept="image/*"
        name="image"
        ref={fileRef}
        className="hidden"
        onChange={handleChange}
      />
      <div className="mt-6">
        <form>
          <div className="flex items-center justify-around gap-5">
            <div className="relative w-[159px] h-[156px] rounded-full border-2">
              <label onClick={handleButtonClick}>
                <p className="absolute z-10  right-3 m-2 bg-[white] cursor-pointer">
                  <FiEdit2 className="text-gray-400 bg-[white]" />
                </p>
              </label>

              <div className="absolute text-sm text-gray-600 text-wrap text-center bottom-12">
                {instructorSignup?.image ? (
                  <img
                    src={instructorSignup?.image}
                    className="relative top-12 left-0 border-none rounded-full w-[155px] h-[155px]"
                    alt="Profile"
                  />
                ) : (
                  " Image size should be 150x200"
                )}
              </div>
            </div>
            <div className="cursor-pointer">
              <p className="text-sm text-gray-900 font-semibold">
                Upload Picture
              </p>
              <span className="text-gray-600 text-sm">(Non Mandatory)</span>
            </div>
          </div>
          <div className="mb-4 mt-6">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Country
            </label>
            <Select
              showSearch
              className="w-full"
              // style={{ width: "300px" }}
              placeholder="Select a Country"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={((countrylist && countrylist) || []).map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />
            {instructorSignup.country === "" && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>

          <div className="mb-4 mt-6">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Contact number
            </label>

            <PhoneInput
              country="in"
              name="contactNumber"
              value={instructorSignup.contactNumber}
              onChange={(phone) =>
                setInstructorSignup({
                  ...instructorSignup,
                  contactNumber: phone,
                })
              }
              countryCodeEditable={false}
              placeholder="mobile number"
              onlyCountries={["in", "us", "au"]}
              inputStyle={{
                width: "100%",
                height: "42px",
                paddingLeft: "42px",
              }}
              dropdownStyle={{ maxWidth: "250px" }}
            />
            {instructorSignup.contactNumber.length < 12 && (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            )}
          </div>

          <div>
            <div className="mb-1">
              <label className="block text-sm text-gray-900 font-semibold mb-4">
                Password
              </label>
              <div className=" relative">
                <input
                  type={
                    showPassword?.password === "password" ? "text" : "password"
                  }
                  name="password"
                  autoComplete="new-password"
                  value={instructorSignup.password}
                  onChange={handleChange}
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  placeholder="Create password"
                />
                <span
                  onClick={() => hideAndShowPassword("password")}
                  className="absolute  right-4 bottom-3 cursor-pointer"
                >
                  {showPassword?.password === "password" ? (
                    <FaRegEye />
                  ) : (
                    <IoEyeOffOutline />
                  )}
                </span>
              </div>
              {instructorSignup.password === "" ? (
                <span className="block text-sm text-red-500 pt-1">
                  This Field is Required !
                </span>
              ) : (
                <span
                  className={`block text-sm  ${
                    errors.password ? "text-red-600 pt-1" : ""
                  }`}
                >
                  {errors.password}
                </span>
              )}
            </div>
          </div>

          <div className="mb-1 mt-4">
            <label className="block text-sm text-gray-900 font-semibold mb-4">
              Confirm Password
            </label>
            <div className=" relative">
              <input
                type={
                  showPassword?.confirmPassword === "confirmPassword"
                    ? "text"
                    : "password"
                }
                name="confirmPassword"
                autoComplete="current-password"
                value={instructorSignup.confirmPassword}
                onChange={handleChange}
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                placeholder="Enter password"
              />
              <span
                onClick={() => hideAndShowPassword("confirmPassword")}
                className="absolute  right-4 bottom-3 cursor-pointer"
              >
                {showPassword?.confirmPassword === "confirmPassword" ? (
                  <FaRegEye />
                ) : (
                  <IoEyeOffOutline />
                )}
              </span>
            </div>
            {instructorSignup.confirmPassword === "" ? (
              <span className="block text-sm text-red-500 pt-1">
                This Field is Required !
              </span>
            ) : (
              instructorSignup.confirmPassword !==
                instructorSignup.password && (
                <span className="block text-sm text-red-500 pt-1">
                  Password didn't match!
                </span>
              )
            )}
          </div>
          {nextMove ? (
            <button
              className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-7 rounded"
              onClick={nextInstructorFinalPage}
            >
              <span className="pr-2">Next </span> <span>&raquo;</span>
            </button>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-7 rounded"
              onClick={(e) => e.preventDefault()}
            >
              <span className="pr-2">Next </span> <span>&raquo;</span>
            </button>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default InstructorMiddleSignup;
