import React from "react";
import { NavLink } from "react-router-dom";
import JoinFreeBtn from "../button/PrimaryButton";
import { motion } from "framer-motion";

const ExploreCourse = (props) => {
  const data = [
    {
      img: "/images/Media (3).png",
      title: "Data Science and Machine Learning",
      about: "Lorem ipsum want to secure your future for better fullfillment",
      rating: 8,
      btnText: "Beginner Course",
    },
    {
      img: "/images/city.png",
      title: "Data Science and Machine Learning",
      about: "Lorem ipsum want to secure your future for better fullfillment",
      rating: 8,
      btnText: "Intermediate Course",
    },
    {
      img: "/images/Media (1).png",
      title: "Data Science and Machine Learning",
      about: "Lorem ipsum want to secure your future for better fullfillment",
      rating: 8,
      btnText: "Beginner Course",
    },
    {
      img: "/images/Media (2).png",
      title: "Data Science and Machine Learning",
      about: "Lorem ipsum want to secure your future for better fullfillment",
      rating: 8,
      btnText: "Beginner Course",
    },
  ];
  return (
    <>
      <div className="container mx-auto pt-5 pb-10">
        <div
          className={`grid grid-cols-1 sm:grid-cols-${props.col1} md:grid-cols-${props.col2} lg:grid-cols-${props.col3} gap-5 py-5 max-sm:justify-items-center max-sm:px-4 `}
        >
          <Card data={data} />
        </div>
      </div>
    </>
  );
};

export default ExploreCourse;

const Card = (props) => {
  return (
    <>
      {props?.data?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div>
              <div className="mt-4 border-solid  w-full max-lg:max-w-[380px] min-h-96  border border-gray-200 md:shadow-md rounded-lg">
                <img
                  className="w-full h-50 rounded-t-lg object-cover"
                  src={item?.img}
                  alt="card-image"
                />

                <div className="py-3 pl-3">
                  <JoinFreeBtn
                    title={item.btnText}
                    bgColor={"bg-blue-600"}
                    color={"text-white"}
                    className="px-[10px]"
                  />
                  <h2 className="font-semibold text-sm text-gray-900 pt-3">
                    {item?.title}
                  </h2>
                  <p className="text-sm pt-1 text-gray-600 pr-1">
                    {item?.about}
                  </p>
                  <div className="py-2">
                    <span>{item?.rating}</span>
                    <span className="px-2">*</span>
                    <span className="text-gray-600 text-sm">(17k views)</span>
                  </div>
                  <div className="flex  justify-end pr-5">
                    <NavLink to="/" className="text-blue-500">
                      Learn more <span>&raquo;</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};
