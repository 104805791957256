import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASEURL;

export function googleLogin(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/auth/google/`, data)
      .then(function (response) {
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          localStorage.setItem(
            "userAuth",
            JSON.stringify(response?.data?.access)
          );
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );

          localStorage.setItem(
            "refreshtoken",
            JSON.stringify(response?.data?.refresh)
          );
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function linkedinLoginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/get_url/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function refreshTokenApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/refresh-token`, data)
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data?.access) {
            localStorage.setItem(
              "userAuth",
              JSON.stringify(response?.data?.access)
            );
          }

          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function userLogin(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/login`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
          toast.success(response?.data?.message);
          if (response?.data?.access) {
            localStorage.setItem(
              "userAuth",
              JSON.stringify(response?.data?.access)
            );
            localStorage.setItem(
              "user_type",
              JSON.stringify(response?.data?.user_type)
            );
            localStorage.setItem(
              "userId",
              JSON.stringify(response?.data?.data?.id)
            );
            localStorage.setItem(
              "userName",
              JSON.stringify(response?.data?.data?.name)
            );
            localStorage.setItem(
              "userEmail",
              JSON.stringify(response?.data?.data?.email)
            );
            localStorage.setItem(
              "userContact",
              JSON.stringify(response?.data?.data?.mobile)
            );
          }
          if (response?.data?.refresh) {
            localStorage.setItem(
              "refreshtoken",
              JSON.stringify(response?.data?.refresh)
            );
          }
        } else {
          reject(response);
          toast.error(response?.data?.message);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function forgetPasswordApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/forgot-request`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
          toast.success(response?.data?.message);
        } else {
          reject(response);
          toast.error(response?.data?.message);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function resesndEmail(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/resend-mail`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function typeOfCountryApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/add/country_list/`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function typeOfUsersApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/user-type`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function studentRegsiterApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/register`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function verifyEmailOtpApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/verify-code`, data)
      .then(function (response) {
        if (response?.status === 201) {
          toast.success(response?.data?.message);
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function forgetPasswordEnd(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}user/forgot-password`, data)
      .then(function (response) {
        if (response?.status === 200) {
          toast.success(response?.data?.message);
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function registerTeacherBySocial(data) {
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
    },
  };
  return new Promise((resolve, reject) =>
    axios
      .put(`${BASE_URL}user/social-user/update`, data, config)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
          toast.success(response?.data?.message);
          localStorage.setItem(
            "user_type",
            JSON.stringify(response?.data?.user_type)
          );
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function userProfileUpdate(data) {
  return new Promise((resolve, reject) =>
    axios
      .patch(`${BASE_URL}user/update/profile/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function instructorCategories(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/category`, data)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function instructorSpecalities(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}user/category-sub?category=${data}`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

// courrse category start
export function courseCategory(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/category/add/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function courseCategoryList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/category/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function createSubjectCategory(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/subject/add/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function listSubjectCategory() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/subject/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function listSubjectBYCategoryId(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(
        `${BASE_URL}lms/subject/27da1bc1-f864-43e4-932a-319c49f9a698/subjects/`
      )
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

//course list
export function createCourse(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/course/create_course/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function courseList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/course/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
export function courseListCopy() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/course/get_level_wise/?level=beginner intermediate`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}
//technology
export function technologyList() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${BASE_URL}lms/technology/`)
      .then(function (response) {
        if (response?.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

export function createTechnology(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${BASE_URL}lms/technology/add_technology/`, data)
      .then(function (response) {
        if (response?.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      })
  );
}

// -------------------get user login detials---------------------
export async function getUserdetails(id) {
  try {
    const res = await axios.get(`${BASE_URL}user/profiles/${id}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data.data && res.data.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get user login detials---------------------

// -------------------create category or sub-category------------
export async function createCategorySubcategory(data) {
  try {
    const res = await axios.post(
      `${BASE_URL}lms/category/create_category/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    toast.success(res.data.message);
  } catch (error) {
    return error.message;
  }
}
// -------------------create category or sub-category------------

// -------------------get category list-------------------
export async function getCategories() {
  try {
    const response = await axios.get(`${BASE_URL}lms/category/get_categories/`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return null;
  }
}
// -------------------get category list-----------------------------------------

// -------------------delete course category list-------------------
export async function deleteCategoryAndSubcategory(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/category/${id}/delete-category/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res.data.error) {
      toast.success(res.data.error);
    } else {
      toast.success("delete successfully!");
    }
  } catch (error) {
    return error.message;
  }
}
// -------------------delete course category list-------------------

// -------------------add course-------------------
export async function addCourse(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course/add/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data.error) {
      toast.success(res.data.error);
      return res.data.error;
    } else {
      toast.success("course add successfully!");
      return res.data.message;
    }
  } catch (error) {
    return error.message;
  }
}
// -------------------add course-------------------

// -------------------get course by id-------------------
export async function getCourseById() {
  const id = localStorage.getItem("courseId");
  try {
    const res = await axios.get(`${BASE_URL}lms/course/${id}/get/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    return error.message;
  }
}
// -------------------get course by id-------------------

// -------------------get course sub category list-------------------
export async function getCourseSubCategoryList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/subject/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get course sub category list-------------------

// -------------------get course list--------------------------------
export async function getCourseList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/course/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get course list--------------------------------

// -------------------get technology list----------------------------
export async function getTechnologyList() {
  try {
    const res = await axios.get(`${BASE_URL}lms/technology`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get technology list----------------------------

// -------------------get users data---------------------------------
export async function getUsersData(user_type) {
  try {
    const res = await axios.get(
      `${BASE_URL}user/admin/user_list/?type=${user_type}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get users data---------------------------------

// -------------------update course data by id-----------------------
export async function updateCourseById(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/course/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------update course data by id-----------------------

// -------------------delete course data by id-----------------------
export async function deleteCourseById(id) {
  try {
    const res = await axios.delete(`${BASE_URL}lms/course/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------delete course data by id-----------------------

// -------------------create course content-----------------------
export async function createCourseContent(courseContent) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course/create_content/`, {
      courseContent,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------create course content-----------------------

// --------------------get course content by course Id-------------------------------------
export async function getCourseContent(course_id) {
  try {
    const res = await axios.get(
      `${BASE_URL}lms/course-files/by-course-id/${course_id}`
    );
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get course content by course Id-------------------------------------

// -------------------update course content-----------------------
export async function updateCourseContent(id) {
  try {
    const res = await axios.put(
      `${BASE_URL}lms/course/update_content/?course_pk=${id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------update course content-----------------------

// -------------------add course videos---------------------------
export async function addCourseVideos(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course/course_video/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------add course videos---------------------------

// -------------------get course videos---------------------------
export async function getCourseVideos(id) {
  try {
    const res = await axios.get(
      `${BASE_URL}lms/course/video_list/?course=${id}`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get course videos---------------------------

// -------------------get teacher profile completed---------------
export async function getTeacherProfileComplete() {
  try {
    const res = await axios.get(
      `${BASE_URL}user/admin/teacher_profile_completed/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get teacher profile completed---------------

// -------------------get state list------------------------------
export async function getStateList() {
  try {
    const res = await axios.get(`${BASE_URL}user/add/state_list/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------get state list------------------------------

// -------------------teacher approved by admin------------------------------
export async function teacherApprovedByAdmin(id) {
  try {
    const res = await axios.get(`${BASE_URL}user/admin/${id}/approve/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    return res.data && res.data;
  } catch (error) {
    return error.message;
  }
}
// -------------------teacher approved by admin------------------------------

// -------------------user logout--------------------------------------------
export async function logoutStudent(navigate) {
  try {
    const res = await axios.get(`${BASE_URL}user/profile/logout/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      localStorage.clear();
      toast.success(res.data.message);
      navigate("/");
      window.location.reload();
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------user logout--------------------------------------------

// -------------------get enrollement----------------------------------------
export async function getEnrollement() {
  try {
    const res = await axios.get(`${BASE_URL}lms/enrollments/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get enrollement-----------------------------------------

// -------------------add enrollement-----------------------------------------
export async function addEnrollement(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/enrollments/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add enrollement-----------------------------------------

// -------------------get payment details-----------------------------------------
export async function getPayment() {
  try {
    const res = await axios.get(`${BASE_URL}lms/payment/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
      return res.data;
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
// -------------------get payment details-----------------------------------------

// -------------------add payment details-----------------------------------------
export async function addPayment(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/payment/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add payment details-----------------------------------------

// -------------------create subadmin-----------------------------------------
export async function createSubAdmin(data) {
  try {
    const res = await axios.post(`${BASE_URL}user/subadmin/post/`, data, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });

    // Handle success response
    if (res.data && res.data.success) {
      toast.success("Sub-admin created successfully!");
    }
  } catch (error) {
    // Handle error response
    if (error.response) {
      const errMsg = error.response.data?.message?.email?.[0];
      if (errMsg) {
        toast.error(errMsg); // Show the specific error message (e.g., email already exists)
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } else {
      console.log("error", error.message); // General error logging
      toast.error("Network error or server is down.");
    }
  }
}

// -------------------create subadmin-----------------------------------------

// -------------------get sub admin data-----------------------------------------
export async function getSubAdmin() {
  try {
    const res = await axios.get(`${BASE_URL}user/admin/subadmin_list/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get sub admin data-----------------------------------------

// -------------------create category and sub category-----------------------------------------
export async function createCategory(data) {
  try {
    const res = await axios.post(
      `${BASE_URL}lms/category/create_category/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------create category and sub category-----------------------------------------

// -------------------delete category by id-----------------------------------------
export async function deleteCategoryById(id) {
  try {
    const res = await axios.delete(
      `${BASE_URL}lms/category/${id}/delete-category/`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------delete category by id-----------------------------------------

// -------------------add course 2nd step-------------------------------------------
export async function addCoursePartial(data) {
  try {
    const formData = new FormData();
    formData.append("visibility", data.visibility);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("category", data.category);
    formData.append("level", data.level);
    formData.append("duration", data.duration);
    formData.append("banner", data.banner);
    formData.append("price", data.price);
    formData.append("discount_percentage", data.discount_percentage);
    formData.append("skill", data.skill);
    formData.append("mode", data.mode);
    formData.append("is_active", data.is_active);
    const res = await axios.post(`${BASE_URL}lms/course/add/`, data, {
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add course 2nd step-------------------------------------------

// -------------------add course 3rd step-------------------------------------------
export async function addCourseFull(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/content/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add course 3rd step-------------------------------------------

// -------------------get course 3rd step-------------------------------------------
export async function getCourseFull(data) {
  try {
    const res = await axios.get(`${BASE_URL}lms/content/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get course 3rd step-------------------------------------------

// -------------------get dashboard data-----------------------------------------
export async function getDashboardData() {
  try {
    const res = await axios.get(`${BASE_URL}lms/summary/get_summary/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res?.data?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get dashboard data-----------------------------------------

// -------------------get course list-----------------------------------------
export async function getCourseListDashboard() {
  try {
    const response = await axios.get(`${BASE_URL}lms/course/list_courses/`, {
      // headers: {
      //   Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      // },
    });
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return null;
  }
}
// -------------------get course list-----------------------------------------

// -------------------get user request-----------------------------------------
export async function getUserRequest() {
  try {
    const res = await axios.get(`${BASE_URL}lms/requests/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get user request-----------------------------------------

// -------------------create user request-----------------------------------------
export async function createUserRequest(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/requests/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------create user request-----------------------------------------

// -------------------get newsLetter information---------------------------------------
export async function getNewLetterInformation() {
  try {
    const res = await axios.get(`${BASE_URL}lms/newsletters/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res?.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------get newsLetter information---------------------------------------

// -------------------delete newsLetterById---------------------------------------
export async function deleteNewsLetterById(id) {
  try {
    const res = await axios.delete(`${BASE_URL}lms/newsletters/${id}/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message || res.data.success);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------delete newsLetterById---------------------------------------

// -------------------edit newsLetterById---------------------------------------
export async function editeNewsLetterById(data) {
  // Function to fetch image and convert it to a Blob
  async function fetchImageAsBlob(imageUrl) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], "image.png", { type: blob.type });
  }
  try {
    const imageFile = await fetchImageAsBlob(data.image);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("image", imageFile);
    const response = await axios.put(
      `${BASE_URL}lms/newsletters/${data.id}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("userAuth")
          )}`,
        },
      }
    );

    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error updating newsletter:", error);
  }
}
// -------------------edit newsLetterById---------------------------------------

// -------------------getCourseProgress---------------------------------------
export async function getCourseProgress(id) {
  try {
    const res = await axios.get(`${BASE_URL}lms/progress/`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------getCourseProgress--------------------------------------

// -------------------add Course Progress---------------------------------------
export async function addCourseProgress(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/progress/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// -------------------add Course Progress--------------------------------------

// --------------------send notification-------------------------------------
export async function sendNotificationByJson(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/notifications/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------send notification-------------------------------------

// --------------------get notification-------------------------------------
export async function getNotification() {
  try {
    const res = await axios.get(`${BASE_URL}lms/notifications/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get notification-------------------------------------

// --------------------create report-------------------------------------
export async function createReport(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/reports/`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------create report-------------------------------------

// --------------------get report-------------------------------------
export async function getReport() {
  try {
    const res = await axios.get(`${BASE_URL}lms/reports/`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get reports-------------------------------------

// --------------------create course content-------------------------------------
export async function addCourseModule(data) {
  try {
    const res = await axios.post(`${BASE_URL}lms/course-files/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userAuth"))}`,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------create course content-------------------------------------

// --------------------dynamic course rate--------------------------------------
export async function fetchExchangeRate(country) {
  try {
    const response = await axios.get(
      `https://api.exchangerate-api.com/v4/latest/INR`
    ); // Replace with your API
    const rate = response.data.rates[country];
    return rate;
  } catch (error) {
    console.error("Error fetching exchange rates", error);
  }
}
// --------------------dynamic course rate--------------------------------------

// --------------------get course by category-------------------------------------
export async function getCourseByCatId(category) {
  try {
    const res = await axios.get(`${BASE_URL}lms/course/category/`, {
      params: {
        category: category,
      },
    });
    if (res?.data) {
      return res.data || {};
    }
  } catch (error) {
    console.log("error", error.message);
  }
}
// --------------------get course by category-------------------------------------
