import React, { useState, useRef, useEffect } from "react";
import StudentSignup from "./student/StudentSignup";
import InstructorSignup from "./instructor/InstructorSignup";
import RadioWithCheck from "./radioCheck/RadioWithCheck";
import StudentFinalSignup from "./student/StudentFinalSignup";
import InstructorMiddleSignup from "./instructor/InstructorMiddleSignup";
import FinalInstructor from "./instructor/FinalInstructor";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { FaLinkedin } from "react-icons/fa";
import {
  changeCredential,
  showSignupModal,
} from "../../../store/features/linkedinLoginSlice";
import {
  googleLoginAsync,
  // clearErrorMsg,
  // showErrorMsg,
  registerTeacherBySocialAsync,
  userTypeAsync,
} from "../../../store/features/AuthSlice";
import ErrorMessage from "../alertmsg/ErrorMessage";
import UniversitySignup from "./university/UniversitySignup";
import UniversityFinalPage from "./university/UniversityFinalPage";
import CorporateSignup from "./corporates/CorporateSignup";
import CorporateFinalPage from "./corporates/CorporateFinalPage";
import GoogleLoginButton from "../login/GoogleLoginButton";
import { ImLinkedin } from "react-icons/im";
import ChooseUserType from "../login/socialSignupTeacher/ChooseUserType";
import { useNavigate } from "react-router-dom";
import { changeShowLogin } from "../../../store/features/linkedinLoginSlice";
const Signup = (props) => {
  // ------------------signup with linkedin-------------------
  const handleLogin = () => {
    const clientId = "77dvnc9s163hhz";
    const LINKEDIN_CLIENT_SCERET = "WPL_AP0.9HdYwh1EdrAIAhTK.MTIzMzE3NTk1";
    const redirectUri = "http://localhost:3000";
    const scope = "openid profile email w_member_social"; // Specify the permissions you need

    // Construct the OAuth URL
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${scope}`;
    // Redirect the user to LinkedIn
    window.location.href = oauthUrl;
  };
  // ------------------signup with linkedin-------------------

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { credential } = useSelector((state) => state.linkedinLogin);
  useEffect(() => {
    dispatch(userTypeAsync());
  }, []);

  const loginRef = useRef(null);
  const [showPassword, setShowPassword] = useState({
    password: null,
    confirmPassword: null,
  });

  const [studentNextModal, setStudentNextModal] = useState(false);
  const [instructorMiddleModal, setInstructorMiddleModal] = useState(false);
  const [instructorFinalModal, setInstructorFinalModal] = useState(false);
  const [universityNextModal, setUniversityNextModal] = useState(false);
  const [corporateNextModal, setCorporateNextModal] = useState(false);
  const [showEmailToast, setShowEmailToast] = useState(false);
  const [goBack, setGoback] = useState(false);
  const [selectedOption, setSelectedOption] = useState("student");
  const [selectedId, setSelectedId] = useState();

  // checkbox for changes modal end

  // handle user Type

  // student formvalues
  const initailValues = {
    studentName: "",
    studentEmail: "",
    studentNumber: "",
    studentPassword: "",
    confirmPassword: "",
    profileImage: null,
    country: "",
    referralCode: "",
    agree: false,
  };
  // Instructor formvalues
  const instructorValues = {
    instructorFullName: "",
    instructorEmail: "",
    image: null,
    country: "",
    contactNumber: "",
    password: "",
    confirmPassword: "",
    qualification: "",
    hours: "",
    income: "",
    category: "",
    specialization: "",
    subject: "",
    idProof: null,
    educationalDocs: null,
    agree: false,
  };
  // University formvalues
  const universityValues = {
    universityName: "",
    universityEmail: "",
    universityNumber: "",
    universityPassword: "",
    confirmPassword: "",
    whichUniversity: "",
    country: "",
    whichAddress: "",
    whichEmail: "",
  };
  // corporate formvalues
  const corporateValues = {
    corporateName: "",
    corporateEmail: "",
    corporateNumber: "",
    corporatePassword: "",
    confirmPassword: "",
    whichcorporate: "",
    country: "",
    whichAddress: "",
    whichEmail: "",
  };
  const [studentFormValues, setStudentFormValues] = useState(initailValues);
  const [instructorSignup, setInstructorSignup] = useState(instructorValues);
  const [universityFormvalues, setUniversityFormValues] =
    useState(universityValues);
  const [corporateFormvalues, setCorporateFormValues] =
    useState(corporateValues);
  const [touchedFields, setTouchedFields] = useState({});
  const [selectedUserType, setSelectedUserType] = useState();

  // instructor first page signup start

  const handleOptionChanges = (option) => {
    setSelectedOption(option);
    setShowPassword({
      password: null,
      confirmPassword: null,
    });
  };

  const hideAndShowPassword = (fieldName) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [fieldName]: prevState[fieldName] === null ? fieldName : null,
    }));
  };

  const handleFieldBlur = (fieldName) => {
    setTouchedFields((prevValue) => ({
      ...prevValue,
      [fieldName]: true,
    }));
  };
  useEffect(() => {
    dispatch(changeCredential(false));
  }, []);
  const NavigateOnLogin = (e) => {
    setShowEmailToast(false);
    dispatch(changeShowLogin(true));
    dispatch(showSignupModal(false));
    props.setShowInstructorJoin(false);
  };
  const studentNextFunction = (e) => {
    e.preventDefault();
    const {
      studentEmail,
      studentName,
      studentNumber,
      studentPassword,
      confirmPassword,
    } = studentFormValues;
    if (
      studentEmail &&
      studentName &&
      studentNumber.length === 12 &&
      studentPassword &&
      confirmPassword &&
      studentPassword === confirmPassword
    ) {
      console.log("studentFormValues", studentFormValues);
      setStudentNextModal(true);
    }
    setGoback(true);
  };

  //instructor middle page  start
  const nextInstructorMiddlePage = (e) => {
    e.preventDefault();
    const { instructorEmail, instructorFullName } = instructorSignup;
    console.log("instructorSignup", instructorSignup);
    if (instructorEmail && instructorFullName) {
      setInstructorMiddleModal(true);
    }

    setGoback(true);
  };
  const nextUniversityPage = (e) => {
    e.preventDefault();
    const {
      universityName,
      universityEmail,
      universityNumber,
      universityPassword,
      confirmPassword,
    } = universityFormvalues;
    if (
      universityName &&
      universityEmail &&
      universityNumber &&
      universityPassword.length >= 8 &&
      confirmPassword &&
      universityPassword === confirmPassword
    ) {
      setUniversityNextModal(true);
    }

    setGoback(true);
  };

  const nextCorporatePage = (e) => {
    const {
      corporateName,
      corporateEmail,
      corporateNumber,
      corporatePassword,
      confirmPassword,
    } = corporateFormvalues;
    e.preventDefault();
    if (
      corporateName !== "" &&
      corporateEmail !== "" &&
      corporateNumber !== "" &&
      corporateNumber?.length === 12 &&
      corporatePassword !== "" &&
      confirmPassword !== "" &&
      corporatePassword === confirmPassword
    ) {
      setCorporateNextModal(true);
    }

    setGoback(true);
  };

  //instructor middle page  end

  //instructor final page  start
  const nextInstructorFinalPage = (e) => {
    e.preventDefault();

    const { country, contactNumber, password, confirmPassword } =
      instructorSignup;
    if (
      country &&
      contactNumber.length === 12 &&
      password &&
      confirmPassword &&
      password === confirmPassword
    ) {
      setInstructorFinalModal(true);
      setInstructorMiddleModal(false);
    }
    setGoback(true);
  };

  //instructor final page  end
  const handleGoBack = () => {
    if (selectedOption == "student") {
      setStudentNextModal(false);
      setGoback(false);
    } else if (
      selectedOption == "teacher" &&
      instructorMiddleModal === true &&
      instructorFinalModal === false
    ) {
      setInstructorMiddleModal(false);
      setGoback(false);
    } else if (
      selectedOption == "teacher" &&
      instructorMiddleModal === false &&
      instructorFinalModal === true
    ) {
      setInstructorMiddleModal(true);
      setInstructorFinalModal(false);
      setGoback(true);
    } else if (selectedOption === "university") {
      setUniversityNextModal(false);
      setGoback(false);
    } else if (selectedOption === "corporate") {
      setCorporateNextModal(false);
      setGoback(false);
    }
  };

  useEffect(() => {
    if (selectedOption == "student") {
      setGoback(false);
    } else if (selectedOption === "teacher") {
      setGoback(false);
    }
  }, [selectedOption]);

  const responseMessage = async (response) => {
    const data = { auth_token: response?.credential };

    // dispatch(googleLoginAsync(data));
    // if (response?.credential !== "") {
    //   props.setCredential(true);
    // } else {
    //   props.setCredential(false);
    // }
    const resultAction = await dispatch(googleLoginAsync(data));
    if (googleLoginAsync.fulfilled.match(resultAction)) {
      const { user_type } = resultAction.payload;

      // dispatch(googleLoginAsync(data));
      if (user_type == null) {
        dispatch(showSignupModal(false));
        dispatch(changeCredential(true));
      } else {
        dispatch(changeCredential(false));
      }

      if (user_type === "student") {
        navigate("/student/dashboard");
      } else if (user_type === "admin") {
        navigate("/admin/dashboard");
      } else if (user_type === "corporate") {
        navigate("/corporate/dashboard");
      } else if (user_type === "teacher") {
        navigate("/teacher/dashboard");
      } else {
        navigate("/");
      }
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const handleOptionChange = (option, id) => {
    setSelectedUserType(option);
    setSelectedId(id);
    // setCredential(false);

    dispatch(changeShowLogin(true));
    dispatch(showSignupModal(false));
  };
  const handleSocialLogin = () => {
    const data = { user_type: selectedId };
    if (
      selectedUserType === "student" ||
      selectedUserType === "university" ||
      (selectedUserType === "corporate" && selectedId !== "")
    ) {
      dispatch(registerTeacherBySocialAsync(data));

      dispatch(changeShowLogin(true));
      dispatch(changeCredential(false));
      dispatch(showSignupModal(false));
    }
  };
  return (
    <>
      {/* {errorMsg && <ErrorMessage />} */}
      <div
        ref={loginRef}
        className={`absolute bg-white p-6 rounded-lg  max-sm:max-w-[85%] max-lg:max-w-[85%] h-[90vh]  overflow-y-auto  ${
          instructorFinalModal ? "w-[900px]" : "w-96"
        }`}
      >
        <div
          className={`flex ${
            goBack ? "justify-between" : "justify-end"
          } items-center cursor-pointer`}
        >
          {goBack ? (
            <button onClick={handleGoBack}>
              <IoArrowBack />
            </button>
          ) : (
            ""
          )}
          <img
            onClick={() => {
              dispatch(showSignupModal(false));
              dispatch(changeCredential(false));
            }}
            src={process.env.PUBLIC_URL + "/images/x.png"}
            alt="cross"
          />
        </div>
        {credential === false ? (
          <div>
            <div className="flex justify-center flex-col items-center">
              <h2 className="font-semibold text-gray-900 text-2xl">Sign Up</h2>

              <div className="w-[320px] m-auto">
                <p className="text-center text-gray-500 text-sm">
                  Join Our Community of Teachers, Students, Corporates, and
                  Universities Unlock Exclusive Resources and Opportunities
                </p>
              </div>
            </div>

            {props.showInstructorJoin === false &&
              studentNextModal === false &&
              universityNextModal === false &&
              instructorMiddleModal === false &&
              corporateNextModal === false &&
              instructorFinalModal === false && (
                <div className="mt-8">
                  <p className="block text-sm text-gray-900 font-semibold mb-4">
                    Select Category
                  </p>
                  <RadioWithCheck
                    handleOptionChanges={handleOptionChanges}
                    selectedOption={selectedOption}
                  />
                </div>
              )}
            {/* student part start */}
            {selectedOption === "student" && studentNextModal === false && (
              <StudentSignup
                hideAndShowPassword={hideAndShowPassword}
                studentNextFunction={studentNextFunction}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                studentFormValues={studentFormValues}
                setStudentFormValues={setStudentFormValues}
              />
            )}
            {selectedOption === "student" && studentNextModal == true && (
              <StudentFinalSignup
                studentFormValues={studentFormValues}
                selectedOption={selectedOption}
                setStudentFormValues={setStudentFormValues}
                NavigateOnLogin={NavigateOnLogin}
              />
            )}
            {/* student part end */}

            {/* instructor  part start */}
            {selectedOption === "teacher" &&
              instructorMiddleModal === false &&
              instructorFinalModal === false && (
                <InstructorSignup
                  instructorSignup={instructorSignup}
                  setInstructorSignup={setInstructorSignup}
                  nextInstructorMiddlePage={nextInstructorMiddlePage}
                />
              )}
            {selectedOption === "teacher" &&
              instructorMiddleModal === true &&
              instructorFinalModal === false && (
                <InstructorMiddleSignup
                  touchedFields={touchedFields}
                  handleFieldBlur={handleFieldBlur}
                  nextInstructorFinalPage={nextInstructorFinalPage}
                  instructorSignup={instructorSignup}
                  setInstructorSignup={setInstructorSignup}
                  hideAndShowPassword={hideAndShowPassword}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              )}
            {selectedOption === "teacher" && instructorFinalModal === true && (
              <FinalInstructor
                touchedFields={touchedFields}
                handleFieldBlur={handleFieldBlur}
                instructorSignup={instructorSignup}
                setInstructorSignup={setInstructorSignup}
                nextInstructorFinalPage={nextInstructorFinalPage}
                selectedOption={selectedOption}
                NavigateOnLogin={NavigateOnLogin}
                setShowEmailToast={setShowEmailToast}
              />
            )}
            {/* instructor  part start */}

            {/* university part start */}
            {selectedOption === "university" &&
              universityNextModal === false && (
                <UniversitySignup
                  handleFieldBlur={handleFieldBlur}
                  touchedFields={touchedFields}
                  universityFormvalues={universityFormvalues}
                  setUniversityFormValues={setUniversityFormValues}
                  hideAndShowPassword={hideAndShowPassword}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  nextUniversityPage={nextUniversityPage}
                />
              )}

            {selectedOption === "university" &&
              universityNextModal === true && (
                <UniversityFinalPage
                  handleFieldBlur={handleFieldBlur}
                  touchedFields={touchedFields}
                  universityFormvalues={universityFormvalues}
                  setUniversityFormValues={setUniversityFormValues}
                  selectedOption={selectedOption}
                  NavigateOnLogin={NavigateOnLogin}
                  setShowEmailToast={setShowEmailToast}
                />
              )}

            {/* university part end */}

            {/* Corporates part start */}
            {selectedOption === "corporate" && corporateNextModal === false && (
              <CorporateSignup
                handleFieldBlur={handleFieldBlur}
                touchedFields={touchedFields}
                corporateFormvalues={corporateFormvalues}
                setCorporateFormValues={setCorporateFormValues}
                hideAndShowPassword={hideAndShowPassword}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                nextCorporatePage={nextCorporatePage}
              />
            )}

            {selectedOption === "corporate" && corporateNextModal === true && (
              <CorporateFinalPage
                handleFieldBlur={handleFieldBlur}
                touchedFields={touchedFields}
                corporateFormvalues={corporateFormvalues}
                setCorporateFormValues={setCorporateFormValues}
                selectedOption={selectedOption}
                NavigateOnLogin={NavigateOnLogin}
              />
            )}

            {/* Corporates part end */}
            {selectedOption !== "teacher" && (
              <div>
                <GoogleLoginButton
                  responseMessage={responseMessage}
                  onError={errorMessage}
                />
                <button className="linkedin-btn" onClick={handleLogin}>
                  <FaLinkedin className="linkedin-icon" />
                  Login with LinkedIn
                </button>
              </div>
            )}

            <div className="flex justify-center items-center mt-4 mb-6">
              <p className="text-gray-500">
                Already on TeacherCool?
                <span
                  className="text-blue-600 cursor-pointer"
                  onClick={NavigateOnLogin}
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        ) : (
          <ChooseUserType
            selectedOption={selectedUserType}
            handleSocialLogin={handleSocialLogin}
            handleOptionChange={handleOptionChange}
          />
        )}
      </div>
    </>
  );
};

export default Signup;
