import React, { useEffect, useState } from "react";

const InstructorSignup = (props) => {
  const [errors, setErrors] = useState({
    email: "",
  });
  const [nextMove, setNextMove] = useState(false);

  const { instructorSignup, setInstructorSignup, nextInstructorMiddlePage } =
    props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    const userName = /^[A-Za-z ]*$/;
    if (value === "" || userName.test(value)) {
      setInstructorSignup((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setInstructorSignup((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (value === "" || emailPattern.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
      setNextMove((data) => !data);
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      setNextMove((data) => !data);
    }
  };

  const handleAlert = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (errors.email === "") {
      setNextMove(true);
    } else {
      setNextMove(false);
    }
  }, [errors.email]);

  return (
    <div>
      <form>
        <div className="mb-4 mt-4">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Full Name
          </label>
          <input
            type="text"
            name="instructorFullName"
            value={instructorSignup.instructorFullName}
            onChange={handleChange}
            className="border bg-[white] border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="Enter your full name"
          />
          {instructorSignup.instructorFullName === "" && (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          )}
        </div>
        <div className="mb-4 mt-1">
          <label className="block text-sm text-gray-900 font-semibold mb-4">
            Email Address
          </label>
          <input
            type="email"
            name="instructorEmail"
            value={instructorSignup.instructorEmail}
            onChange={handleEmailChange}
            className="border bg-[white] border-gray-300 rounded-md px-3 py-2 w-full"
            placeholder="name@gmail.com"
          />
          {instructorSignup.instructorEmail === "" ? (
            <span className="block text-sm text-red-500 pt-1">
              This Field is Required !
            </span>
          ) : (
            <span
              className={`block text-sm  ${
                errors.email == "" ? "" : "text-red-600 pt-1"
              }`}
            >
              {errors.email}
            </span>
          )}
        </div>
        {nextMove ? (
          <button
            onClick={nextInstructorMiddlePage}
            className="bg-blue-500 hover:bg-blue-600 w-[100%] text-white font-semibold py-2 px-4 mt-7 rounded"
          >
            <span className="pr-2">Next </span> <span>&raquo;</span>
          </button>
        ) : (
          <button
            onClick={(e) => handleAlert(e)}
            className="bg-blue-500 hover:bg-blue-600 w-[100%] text-white font-semibold py-2 px-4 mt-7 rounded"
          >
            <span className="pr-2">Next </span> <span>&raquo;</span>
          </button>
        )}
      </form>
      <div>
        <div className="mb-4 mt-7 flex">
          <hr className="border-gray-300 my-4 inline-block w-1/2" />
          <span className="text-gray-600 mx-3 my-1">or</span>
          <hr className="border-gray-300 my-4 inline-block w-1/2" />
        </div>
      </div>
    </div>
  );
};

export default InstructorSignup;
