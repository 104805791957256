import React from "react";
import JoinFreeBtn from "../button/PrimaryButton";
import "../../assests/css/hero.css";
import Group_one from "../../assests/images/Group_one.png";
import Navigation from "../../components/pages/navigation/TopNavigation";

import { motion } from "framer-motion";
import BottomNav from "./navigation/BottomNavigation";
import Forindividual from "../../studentdashboard/Forindividual";
const Hero = () => {
  return (
    <>
      <BottomNav />
      {/* <Forindividual /> */}
      <div className="main_hero_div flex flex-col px-10 lg:flex-row lg:justify-between lg:items-center">
        {/* First Column */}
        <div className="lg:w-1/2">
          {/* Title */}
          <div>
            <h1 className="text-[40px] lg:text-[56px] font-bold text-center lg:text-left">
              Learn Without Limits
            </h1>
          </div>

          {/* Paragraph */}
          <div className="max-w-[600px] mx-auto flex justify-center lg:justify-start items-center pt-5 max-sm:mx-2">
            <p className="text-center lg:text-left text-gray-700 break-words text-wrap">
              Welcome to TeacherCool, where knowledge meets innovation, and
              learning becomes an exciting journey! As a premier online platform
              for IT-based training, TeacherCool is committed to revolutionizing
              education by providing comprehensive, cutting-edge courses that
              empower individuals to thrive in the dynamic world of Information
              Technology. TeacherCool comes as a part of GYPR , dedicated
              towards transforming online learning for both IT and non-IT
              students. With the rich legacy and expertise of GYPR, we are
              carving our own learning path, providing top-notch educational
              experiences for modern learners.
            </p>
          </div>

          {/* Buttons */}
          <div className="flex justify-center lg:justify-start items-center my-10 gap-5 lg:gap-10 max-sm:flex-col">
            <JoinFreeBtn
              title={"Join for Free"}
              bgColor={"bg-blue-600"}
              color={"text-white"}
              fontSize={"text-xl"}
            />
            <button className="text-xl text-[#0966ED] font-medium border-blue-400 border-2 rounded px-5 py-[6px]">
              Try CoolTeacher for Universities
            </button>
          </div>
        </div>

        {/* Second Column - Image */}
        <div className="lg:w-1/2 mt-5 lg:mt-0 flex justify-center lg:justify-end">
          <img
            src={process.env.PUBLIC_URL + "/images/Hero image.png"}
            alt="Hero Image"
            className="w-full max-w-[500px] h-auto"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
