import React, { useState, useEffect } from "react";
import "../../assests/css/courses.css";
import roundedbar from "../../assests/images/roundedbar.png";
import Card from "../card/Card";
import { NavLink } from "react-router-dom";
import { getCategories, getCourseByCatId } from "../../store/authApi";
const CoursesComp = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [catId, setCatId] = useState("");
  useEffect(() => {
    getCategories().then((res) => {
      setCategories(res?.data);
      setCatId(res?.data[0]?.id);
    });
  }, []);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    catId && getCourseByCatId(catId).then((res) => setCourseData(res?.data));
  }, [catId]);
  return (
    <>
      <div className="container mx-auto max-sm:px-4 ">
        <h2 className="font-semibold text-3xl">
          Get Started with your Courses today!
        </h2>

        <div className="relative h-[2.23rem] mb-2">
          <div className="for_listing_courses">
            {categories &&
              categories.map((cat, index) => {
                return (
                  <p
                    className={activeIndex === index ? "active" : ""}
                    onClick={() => {
                      setCatId(cat.id);
                      handleItemClick(index);
                    }}
                  >
                    {cat.cat_name}
                  </p>
                );
              })}
          </div>
          <hr
            style={{
              backgroundColor: "#e5e7e8",
              width: "100%",
              height: "4px",
              position: "absolute",
              bottom: "0px",
              zIndex: "-1",
            }}
          />
        </div>
        <div className="border-2 border-solid border-gray-300  rounded ">
          <div className="mt-10 mx-10  max-sm:mx-2 max-lg:mt-5">
            <h1 className="font-semibold text-3xl max-lg:text-center">
              Data Analyst
            </h1>
            <div className="flex justify-between items-center">
              <p className="text-textColor max-lg:text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                suscipit rhoncus nunc id{" "}
              </p>
              <NavLink
                to="/"
                className="font-medium text-base text-gray-900 shadow-md"
              >
                Explore all courses <span>&raquo;</span>
              </NavLink>
            </div>
            <div className="mt-10 mb-2 flex flex-row justify-between  gap-[100px] max-xl:gap-[100px] max-lg:flex-col max-lg:items-center max-lg:gap-5">
              <div className="min-w-40">
                <img src={roundedbar} alt="graph" className="pb-8" />
                <hr className="border-gray-300  m-auto h-4  " />
                <div className="flex pt-2">
                  <p className="text-gray-600 ">Job openings:</p>
                  <p className="pl-2">82,489</p>
                </div>
              </div>
              <div className="flex flex-col  ">
                <h2 className="pt-10 font-semibold ">
                  Recommended Professional Certificates
                </h2>
                <div className="flex w-full max-w-[800px] overflow-x-auto gap-12 max-xl:gap-5 max-sm:gap-2 max-sm:flex-wrap max-sm:justify-center">
                  {courseData.length > 0 ? (
                    <Card data={courseData} />
                  ) : (
                    <div className="h-[17rem] w-full flex flex-col items-center justify-center">
                      <h2 className="text-2xl sm:text-3xl lg:text-4xl mt-3 font-semibold text-gray-700 mb-2">
                        Data not found!
                      </h2>
                      <p className="text-base sm:text-lg lg:text-xl text-gray-500 mb-6">
                        Sorry, we couldn’t find the data you’re looking for.
                        Please try again later.
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-end items-center pt-6 pb-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesComp;

// const DataAnalyst = () => {

//   return (
//     <>
//       <div className="border-2 border-solid border-gray-300  rounded ">
//         <div className="mt-10 mx-10  max-sm:mx-2 max-lg:mt-5">
//           <h1 className="font-semibold text-3xl max-lg:text-center">
//             Data Analyst
//           </h1>
//           <div className="flex justify-between items-center">
//             <p className="text-textColor max-lg:text-center">
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
//               suscipit rhoncus nunc id{" "}
//             </p>
//             <NavLink
//               to="/"
//               className="font-medium text-base text-gray-900 shadow-md"
//             >
//               Explore all courses <span>&raquo;</span>
//             </NavLink>
//           </div>
//           <div className="mt-10 mb-2 flex flex-row justify-between  gap-[100px] max-xl:gap-[100px] max-lg:flex-col max-lg:items-center max-lg:gap-5">
//             <div className="min-w-40">
//               <img src={roundedbar} alt="graph" className="pb-8" />
//               <hr className="border-gray-300  m-auto h-4  " />
//               <div className="flex pt-2">
//                 <p className="text-gray-600 ">Job openings:</p>
//                 <p className="pl-2">82,489</p>
//               </div>
//             </div>
//             <div className="flex justify-between  flex-col  ">
//               <h2 className="pt-10 font-semibold ">
//                 Recommended Professional Certificates
//               </h2>
//               <div className="flex w-[800px] overflow-x-scroll gap-12 max-xl:gap-5 max-sm:gap-2 max-sm:flex-wrap max-sm:justify-center">
//                 <Card data={courseData} />
//               </div>
//               <div className="flex justify-end items-center pt-6 pb-4"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
