import React from "react";

const Footer = () => {
  const footerData = [
    "About",
    "Universities & Corporates",
    "Courses We Offer",
    "Terms and Conditions",
    "Privacy Policy",
  ];
  return (
    <div className="bg-[#E8E9EC] w-[100%] pt-[2.5rem] pb-[3.06rem] flex flex-col gap-4  px-5">
      <ul className="flex md:gap-10 justify-center text-[#0966ED] md:list-disc flex-wrap">
        {footerData?.map((item, index) => {
          return (
            <li className="cursor-pointer px-2" key={index}>
              {item}
            </li>
          );
        })}
      </ul>
      <div className="flex flex-col items-center gap-2 w-[100%]">
        <div>
          Address :B-99, 5th Floor, Phase-I Panchsheel Park Malviya Nagar, Near
          Triveni Complex New Delhi - 110017
        </div>
        <div>
          © {`${new Date().getFullYear()}`} TeacherCool inc.. All rights
          reserved. The Certification names are the trademarks of their
          respective owner
        </div>
      </div>
    </div>
  );
};

export default Footer;
