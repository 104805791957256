import React, { useState } from "react";
import Skillgain from "./selectedCourse/Skillgain";
import StudyMaterial from "./selectedCourse/StudyMaterial";
import Debugging from "./selectedCourse/Debugging";
import ManageCertificates from "./selectedCourse/ManageCertificates";
import LearningLab from "./selectedCourse/LearningLab";
import RecommendedCourse from "./selectedCourse/RecommendedCourse";
import pdfUrl from "../../assests/courses/Angular.pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Slider from "react-slick";
import { FaCheckCircle } from "react-icons/fa";
/* In your main CSS file or component file */

const testimonials = [
  {
    id: 1,
    name: "Olivia H., Front-End Developer",
    testimonial:
      '"This course exceeded my expectations! The modules on routing and forms were particularly helpful, and I can now create dynamic, user-friendly applications."',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Liam T., Software Engineer",
    testimonial:
      '"I had some experience with web development, but this course helped me understand Angular in depth. The explanations were clear, and the focus on best practices was invaluable."',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Mia S., Web Developer",
    testimonial:
      '"I loved this course! The practical examples and real-world applications helped solidify my understanding of Angular. I’m excited to apply what I’ve learned to my projects!"',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    name: "Noah P., Junior Developer",
    testimonial:
      '"This course exceeded my expectations! The modules on routing and forms were particularly helpful, and I can now create dynamic, user-friendly applications."',
    image: "https://via.placeholder.com/150",
  },
  {
    id: 5,
    name: "Ava R., UI/UX Designer",
    testimonial:
      '"I found this course to be extremely well-structured. The focus on component design and performance optimization has significantly improved my development skills!"',
    image: "https://via.placeholder.com/150",
  },
];

const SelectedCourseDetail = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCourse, setActiveCourse] = useState("About");
  const courses = [
    { name: "About" },
    { name: "Learning Outcomes" },
    { name: "Testimonials" },
    { name: "Course Brief" },
    { name: "Recommendations" },
  ];

  return (
    <div>
      <div className="relative">
        <div className="flex flex-wrap gap-4 md:gap-8 my-5">
          {courses.map((course, index) => (
            <div key={index}>
              <p
                className={`cursor-pointer font-medium text-base font-Roboto px-2 py-1 mb-3 rounded-md ${
                  activeIndex === index
                    ? "text-blue-500 bg-[#F4F6FC] border-blue-500"
                    : "text-[#1E1E1E] text-opacity-85"
                }`}
                onClick={() => {
                  setActiveIndex(index);
                  setActiveCourse(course.name);
                }}
              >
                {course.name}
              </p>
              <p
                className={`${
                  activeIndex === index ? "border-blue-500 border-b-4" : "none"
                }`}
              ></p>
            </div>
          ))}
          <hr
            style={{
              backgroundColor: "#e5e7e8",
              minWidth: "90%",
              height: "4px",
              position: "absolute",
              bottom: "0px",
              zIndex: "-1",
            }}
          />
        </div>
      </div>
      <div className="pb-5">
        {activeCourse == "About" && (
          <>
            <div className="pt-4 pb-6">
              <p className="font-Roboto font-semibold text-lg">
                What you'll learn
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 mb-8 gap-4">
              <div className="flex items-start gap-4 col-span-1 md:col-span-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/student/sign.png"}
                  alt="icon"
                  className="w-5 h-5"
                />
                <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
                  Understand Angular fundamentals, including architecture,
                  components, and modules.
                </p>
              </div>
              <div className="flex items-start gap-4 col-span-1 md:col-span-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/student/sign.png"}
                  alt="icon"
                  className="w-5 h-5"
                />
                <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
                  Create responsive and interactive user interfaces using
                  templating syntax and data binding.
                </p>
              </div>
              <div className="flex items-start gap-4 col-span-1 md:col-span-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/student/sign.png"}
                  alt="icon"
                  className="w-5 h-5"
                />
                <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
                  Engage in hands-on learning through practical projects to
                  build real-world applications.
                </p>
              </div>
              <div className="flex items-start gap-4 col-span-1 md:col-span-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/student/sign.png"}
                  alt="icon"
                  className="w-5 h-5"
                />
                <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
                  Explore advanced topics like routing, forms, and services for
                  data management.
                </p>
              </div>
              <div className="flex items-start gap-4 col-span-1 md:col-span-4">
                <img
                  src={process.env.PUBLIC_URL + "/images/student/sign.png"}
                  alt="icon"
                  className="w-5 h-5"
                />
                <p className="font-Roboto text-sm text-[#1E1E1E] text-opacity-80">
                  Implement best practices for performance optimization and
                  testing.Strengthen employability to attract more students
                </p>
              </div>
            </div>
          </>
        )}
        {activeCourse == "Learning Outcomes" && <LearningOutcomes />}
        {activeCourse == "Course Brief" && (
          <div className="border w-full shadow-md rounded-md p-4">
            <h3 className="text-xl font-bold mb-4">PDF Preview</h3>
            <div className="overflow-auto border">
              <iframe
                src={pdfUrl}
                style={{ width: "100%", height: "580px", border: "none" }}
                title="PDF Document"
              />
            </div>
          </div>
        )}
        {activeCourse == "Testimonials" && <TestimonialSlider />}

        <Skillgain />
        <StudyMaterial />
        <Debugging />
        <ManageCertificates />
        <LearningLab />
        <RecommendedCourse />
      </div>
    </div>
  );
};

export default SelectedCourseDetail;

const LearningOutcomes = () => {
  const outcomes = [
    {
      title: "Understand Angular Fundamentals",
      details: [
        "Grasp the core concepts of Angular, including modules, components, and services.",
        "Recognize the advantages of using Angular for front-end development.",
      ],
    },
    {
      title: "Create Angular Components",
      details: [
        "Build reusable components and manage their lifecycle effectively.",
        "Implement input and output properties to communicate between components.",
      ],
    },
    {
      title: "Utilize Angular Directives",
      details: [
        "Use built-in directives to manipulate the DOM and enhance user interfaces.",
        "Create custom directives to encapsulate reusable behaviors.",
      ],
    },
    {
      title: "Implement Data Binding",
      details: [
        "Understand the different types of data binding in Angular, including one-way and two-way binding.",
        "Use interpolation, property binding, and event binding to manage data flow.",
      ],
    },
    {
      title: "Manage Routing and Navigation",
      details: [
        "Implement Angular Router to create single-page applications (SPAs) with seamless navigation.",
        "Handle route parameters, lazy loading, and nested routes for complex applications.",
      ],
    },
    {
      title: "Work with Forms",
      details: [
        "Build and manage reactive and template-driven forms for user input.",
        "Implement form validation to ensure data integrity.",
      ],
    },
    {
      title: "Create and Use Services",
      details: [
        "Understand the role of services in Angular for sharing data and functionality across components.",
        "Use dependency injection to manage services effectively.",
      ],
    },
    {
      title: "Optimize Performance",
      details: [
        "Identify performance bottlenecks in Angular applications and implement optimization techniques.",
        "Utilize tools such as Angular DevTools to analyze and improve application performance.",
      ],
    },
    {
      title: "Test Angular Applications",
      details: [
        "Learn the fundamentals of testing in Angular, including unit tests and end-to-end tests.",
        "Use testing frameworks like Jasmine and Protractor to ensure application reliability.",
      ],
    },
    {
      title: "Deploy Angular Applications",
      details: [
        "Prepare your Angular applications for production deployment.",
        "Explore deployment options and best practices for hosting your applications.",
      ],
    },
  ];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
        Learning Outcomes
      </h2>
      <ul className="space-y-8">
        {outcomes.map((outcome, index) => (
          <li key={index} className="flex">
            <FaCheckCircle className="text-green-500 text-3xl mr-4" />
            <div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {index + 1}. {outcome.title}
              </h3>
              <ul className="ml-6 list-disc list-inside text-gray-600 space-y-1">
                {outcome.details.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 2, // Show two cards at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For mobile view
        settings: {
          slidesToShow: 1, // Show one card on smaller screens
        },
      },
    ],
  };

  return (
    <div className="w-4/5 mx-auto">
      <h2 className="text-3xl font-semibold text-center mb-6">Testimonials</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <TestimonialCard
            key={testimonial.id}
            name={testimonial.name}
            testimonial={testimonial.testimonial}
            image={testimonial.image}
          />
        ))}
      </Slider>
    </div>
  );
};

const TestimonialCard = ({ name, testimonial, image }) => {
  return (
    <div className="p-6 border border-gray-200 rounded-lg shadow-md text-center bg-white hover:shadow-lg transition-shadow duration-300 mx-3">
      <img
        src={image}
        alt={name}
        className="w-24 h-24 mx-auto rounded-full object-cover"
      />
      <h4 className="mt-4 text-xl font-semibold text-gray-800">{name}</h4>
      <p className="mt-2 text-gray-600 italic">{testimonial}</p>
    </div>
  );
};
