import React from "react";
import LabCard from "./card/LabCard";
import { NavLink } from "react-router-dom";

const LearningLab = () => {
  return (
    <div className="my-24">
      <h1 className="text-2xl font-Roboto text-[#1E1E1E] font-semibold">
        Learning Labs
      </h1>
      <p className="text-sm font-Roboto text-[#1E1E1E] text-opacity-70 py-2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur.
      </p>
      <NavLink className="text-sm font-semibold font-Roboto text-[#0966ED] border-b-2 border-blue-500 ">
        Read more
      </NavLink>
      <div className="mt-10">
        <LabCard />
      </div>
    </div>
  );
};

export default LearningLab;
