import React, { useState, useRef, useEffect } from "react";

import { FaRegEye } from "react-icons/fa6";
import { IoEyeOffOutline } from "react-icons/io5";
import ForgetPassword from "../password/ForgetPassword";

import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import {
  googleLoginAsync,
  registerTeacherBySocialAsync,
  userLoginAsync,
  userTypeAsync,
} from "../../../store/features/AuthSlice";
import GoogleLoginButton from "./GoogleLoginButton";
import ChooseUserType from "./socialSignupTeacher/ChooseUserType";
import { useNavigate, useLocation } from "react-router-dom";
import LinkdinLogin from "./socialSignupTeacher/LinkdinLogin";
import { ExtractToken } from "./ExtractToken";
import {
  changeCredential,
  changeShowLogin,
  showSignupModal,
} from "../../../store/features/linkedinLoginSlice";

const Login = (props) => {
  const { credential } = useSelector((state) => state.linkedinLogin);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userTypeAsync());
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const initialValues = { email: "", password: "" };
  const [formvalues, setFormValues] = useState(initialValues);
  const loginRef = useRef(null);

  const [selectedOption, setSelectedOption] = useState();
  const [selectedId, setSelectedId] = useState();

  const getAuthorizationCode = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    localStorage.setItem("code", JSON.stringify(code));
    return code;
  };

  // Call this function on the page where LinkedIn redirects the user back
  const handleRedirect = () => {
    const authorizationCode = getAuthorizationCode();
    localStorage.setItem(
      "authorizationCode",
      JSON.stringify(authorizationCode)
    );
    if (authorizationCode) {
      console.log("Authorization Code:", authorizationCode);
      // You can now send this code to your backend server to exchange it for an access token
    } else {
      console.log("Authorization code not found");
    }
  };
  useEffect(() => {
    handleRedirect();
  }, [window.onload]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      setFormValues({ ...formvalues, [name]: value });
    } else {
      setFormValues({ ...formvalues, [name]: "" });
    }
  };

  const handleChaneIcon = () => {
    setShowPassword((data) => !data);
  };
  const handleSignup = () => {
    dispatch(changeCredential(false));
    dispatch(changeShowLogin(false));
    dispatch(showSignupModal(true));
  };
  const handleForgetPassword = () => {
    props.setForgetPassword("forgetPassword");
  };
  const handleCloseIcon = () => {
    dispatch(changeShowLogin(false));
    dispatch(changeCredential(false));
  };
  const centerLogin = () => {
    const windowHeight = window.innerHeight;
    const loginHeight = loginRef.current.clientHeight;
    const topPosition = (windowHeight - loginHeight) / 2;
    loginRef.current.style.top = `${topPosition}px`;
  };
  useEffect(() => {
    centerLogin();
    window.addEventListener("resize", centerLogin);
    return () => window.removeEventListener("resize", centerLogin);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      email: formvalues.email,
      password: formvalues.password,
    };
    if (formvalues.email !== "" && formvalues.password !== "") {
      const resultAction = await dispatch(userLoginAsync(data));
      if (userLoginAsync.fulfilled.match(resultAction)) {
        const { user_type } = resultAction.payload;
        dispatch(changeShowLogin(false));
        if (user_type === "student") {
          navigate("/student/dashboard");
        } else if (user_type === "admin") {
          navigate("/admin/dashboard");
        } else if (user_type === "corporate") {
          navigate("/corporate/dashboard");
        } else if (user_type === "teacher") {
          navigate("/teacher/dashboard");
        } else {
          navigate("/");
        }
      }
    }
  };

  const responseMessage = async (response) => {
    const data = { auth_token: response?.credential };
    const resultAction = await dispatch(googleLoginAsync(data));
    if (googleLoginAsync?.fulfilled?.match(resultAction)) {
      const { user_type } = resultAction?.payload;

      // dispatch(googleLoginAsync(data));
      if (!resultAction?.payload.hasOwnProperty("user_type")) {
        dispatch(changeCredential(true));
      } else {
        dispatch(changeCredential(false));
      }

      if (user_type === "student") {
        navigate("/student/dashboard");
      } else if (user_type === "admin") {
        navigate("/admin/dashboard");
      } else if (user_type === "corporate") {
        navigate("/corporate/dashboard");
      } else if (user_type === "teacher") {
        navigate("/teacher/dashboard");
      } else {
        navigate("/");
      }
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const handleOptionChange = (option, id) => {
    setSelectedOption(option);
    setSelectedId(id);
    dispatch(changeShowLogin(true));
  };

  const handleSocialLogin = async () => {
    const data = { user_type: selectedId };
    if (
      selectedOption === "student" ||
      selectedOption === "university" ||
      (selectedOption === "corporate" && selectedId !== "")
    ) {
      // dispatch(registerTeacherBySocialAsync(data));
      // dispatch(changeShowLogin(true));
      // dispatch(changeCredential(false));
      const resultAction = await dispatch(registerTeacherBySocialAsync(data));
      console.log("resultAction.payload", resultAction.payload);
      if (registerTeacherBySocialAsync.fulfilled.match(resultAction)) {
        const { user_type } = resultAction.payload;
        if (user_type === "student") {
          navigate("/student/dashboard");
        } else if (user_type === "admin") {
          navigate("/admin/dashboard");
        } else if (user_type === "corporate") {
          navigate("/corporate/dashboard");
        } else if (user_type === "teacher") {
          navigate("/teacher/dashboard");
        } else {
          navigate("/");
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div
        ref={loginRef}
        className={`absolute bg-white p-6 rounded-lg  max-sm:max-w-[85%] w-96 max-h-screen overflow-y-auto`}
      >
        {props.forgetPassword === "login" ? (
          <div>
            <div className="flex justify-center">
              <h2 className="font-semibold text-gray-900 text-2xl">
                Welcome Back
              </h2>
              <div
                className="absolute right-5 top-7 cursor-pointer"
                onClick={handleCloseIcon}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/x.png"}
                  alt="cross"
                />
              </div>
            </div>
            {credential === false ? (
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 mt-10">
                    <label className="block text-sm text-gray-900 font-semibold mb-4">
                      Email Address / Phone no.
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formvalues.email}
                      onChange={handleChange}
                      className="border bg-[white] border-gray-300 rounded-md px-3 py-2 w-full"
                      placeholder="name@gmail.com"
                    />
                  </div>
                  <div className="mb-1">
                    <label className="block text-sm text-gray-900 font-semibold mb-4">
                      Password
                    </label>
                    <div className=" relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={formvalues.password}
                        name="password"
                        onChange={handleChange}
                        className="border border-gray-300 rounded-md px-3 py-2 w-full"
                        placeholder="Password"
                      />
                      <span
                        onClick={handleChaneIcon}
                        className="absolute  right-4 bottom-3 cursor-pointer"
                      >
                        {showPassword ? <IoEyeOffOutline /> : <FaRegEye />}
                      </span>
                    </div>
                  </div>
                  <span
                    className="block text-sm text-blue-500 cursor-pointer"
                    onClick={handleForgetPassword}
                  >
                    Forget Password?
                  </span>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 w-full text-white font-semi py-2 px-4 mt-7 rounded"
                  >
                    Join for Free
                  </button>
                </form>
                <div className="mb-4 mt-7 flex">
                  <hr className="border-gray-300 my-4 inline-block w-1/2" />
                  <span className="text-gray-600 mx-3 my-1">or</span>
                  <hr className="border-gray-300 my-4 inline-block w-1/2" />
                </div>
                {/* ---------------------Login with Google---------------- */}
                <GoogleLoginButton
                  responseMessage={responseMessage}
                  onError={errorMessage}
                />
                {/* ------------------Login with LinkedIn ---------------- */}
                <LinkdinLogin />

                <div className="flex justify-center items-center mt-4 mb-4">
                  <p className="text-gray-500">
                    New to TeacherCool?
                    <span
                      className="text-blue-600 cursor-pointer"
                      onClick={() => handleSignup()}
                    >
                      Signup
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <ChooseUserType
                selectedOption={selectedOption}
                handleSocialLogin={handleSocialLogin}
                handleOptionChange={handleOptionChange}
              />
            )}
          </div>
        ) : (
          <ForgetPassword
            setForgetPassword={props.setForgetPassword}
            setVerifyEmail={setVerifyEmail}
            verifyEmail={verifyEmail}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Login;
