import React from "react";
import { Rate } from "antd";
import { motion } from "framer-motion";

const RecomendCoursecard = () => {
  const data = [
    {
      img: "recommend",
      title: "Beginner's Guide to Design",
      name: "By Ronald Richards",
      rate: 5,
      ratings: 1200,
      course: ["Beginner Course", "Intermediate Course", "Advance Course"],
    },
    {
      img: "recommend",
      title: "Intermediate Guide to Design",
      name: "By Saphew",
      rate: 3.2,
      ratings: 1050,
      course: ["Beginner Course", "Intermediate Course", "Advance Course"],
    },
    {
      img: "recommend",
      title: "Beginner's Guide to Design",
      name: "By Smith",
      rate: 4.2,
      ratings: 170,
      course: ["Beginner Course", "Intermediate Course", "Advance Course"],
    },
    {
      img: "recommend",
      title: "Advance Guide to Design",
      name: "By Ronald Richards",
      rate: 4.5,
      ratings: 800,
      course: ["Beginner Course", "Intermediate Course", "Advance Course"],
    },
  ];

  return (
    <div className="my-5">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <motion.div
                key={index}
                className="border border-[#E2E8F0] rounded-md shadow-md flex flex-col transition duration-700 hover:scale-110 px-2 py-2 my-1"
                whileHover={{ scale: 1.05 }}
              >
                <div className="px-1 py-1">
                  <img
                    src={
                      process.env.PUBLIC_URL + `/images/student/${item.img}.png`
                    }
                    alt="courses"
                    className="w-full h-auto rounded-t-md"
                  />
                </div>

                <span className="text-lg font-Roboto font-medium text-[#1E1E1E] text-opacity-80 pt-2 pb-1">
                  {item.title}
                </span>
                <span className="text-sm font-Roboto text-[#1E1E1E] text-opacity-80 pb-1 pt-1">
                  {item.name}
                </span>

                <div className="flex gap-1 items-center my-2">
                  <Rate allowHalf value={item.rate} />
                  <span className="font-Roboto text-xs text-[#1E1E1E] text-opacity-80">
                    {`(${item.ratings} Ratings)`}
                  </span>
                </div>
                <ul className="flex gap-3 flex-wrap my-5">
                  {item.course.map((course, courseIndex) => (
                    <li
                      className="text-xs font-Roboto text-[#1E1E1E] text-opacity-95 px-2 py-1 rounded-md bg-[#EEF2FF] leading-3"
                      key={courseIndex}
                    >
                      {course}
                    </li>
                  ))}
                </ul>
              </motion.div>
            );
          })}
      </div>
    </div>
  );
};

export default RecomendCoursecard;
