import React from "react";
import { NavLink } from "react-router-dom";
import SelectedCourseCard from "./card/SelectedCourseCard";

const StudyMaterial = () => {
  return (
    <div>
      <div className="pt-10 pb-3 ">
        <h1 className="font-Roboto font-semibold text-2xl text-[#1E1E1E] ">
          Study Materials for this course
        </h1>
      </div>
      <div className="">
        <p className="text-sm text-[#1E1E1E] text-opacity-70 font-Roboto pb-1">
          Welcome to 'Testing and Debugging in React.js'! This module focuses on
          essential practices for web Development with React.js. Learn to plan
          and execute unit tests, and utilize debugging tools effectively.{" "}
        </p>
        <NavLink className="text-sm font-semibold font-Roboto text-[#0966ED] border-b-2 border-blue-500 ">
          Read more
        </NavLink>
      </div>
      <div className="mt-10">
        <SelectedCourseCard />
      </div>
    </div>
  );
};

export default StudyMaterial;
