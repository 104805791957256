import React, { useState, useEffect } from "react";
import Card from "../card/Card";
import { NavLink } from "react-router-dom";
import { getCourseListDashboard } from "../../store/authApi";

const CourseToday = () => {
  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    getCourseListDashboard().then((res) => setCourseData(res));
  }, []);
  return (
    <>
      <div className="container mx-auto pt-5 pb-10">
        <div>
          <h2 className="font-bold text-4xl max-sm:text-2xl max-sm:px-3 py-2">
            Get Started with your Courses today!
          </h2>
          <div className="flex justify-between items-center">
            <p className="text-base pt-1 text-gray-500 max-sm:font-medium font-semibold pr-1 text-wrap max-sm:px-3">
              Get started with your courses today and unlock new opportunities
              for learning and growth.
            </p>
            <NavLink
              to="/"
              className="font-medium text-base text-gray-900 shadow-md"
            >
              Explore all courses <span>&raquo;</span>
            </NavLink>
          </div>
        </div>
        <div className="flex gap-5 flex-wrap">
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 py-5 max-sm:justify-items-center max-sm:px-4 "> */}
          {courseData?.length > 0 ? (
            <Card data={courseData} />
          ) : (
            <div className="h-[17rem] w-full flex flex-col items-center justify-center">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl mt-3 font-semibold text-gray-700 mb-2">
                Data not found!
              </h2>
              <p className="text-base sm:text-lg lg:text-xl text-gray-500 mb-6">
                Sorry, we couldn’t find the data you’re looking for. Please try
                again later.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseToday;
