export const faqData = [
  {
    question: { __html: "1. What is this course about?" },
    answer: {
      __html:
        "This course is designed to provide you with comprehensive knowledge and practical skills in website development. You'll learn everything from the fundamentals of HTML, CSS, and JavaScript to advanced topics like responsive design and web accessibility.",
    },
  },
  {
    question: {
      __html:
        "2. Do I need any prior experience in programming or web development?",
    },
    answer: {
      __html:
        "No prior experience is required. This course is suitable for beginners as well as those with some experience in programming. Our modules are structured to accommodate learners with varying levels of expertise.",
    },
  },
  {
    question: { __html: "3. How is the course structured?" },
    answer: {
      __html:
        "The course is divided into modules, each covering a specific aspect of website development. You'll have access to video lectures, hands-on coding exercises, quizzes, and projects to reinforce your learning.",
    },
  },
  {
    question: {
      __html: "4. What software/tools do I need to complete the course?",
    },
    answer: {
      __html:
        "You'll need a computer with internet access. Additionally, you'll need a text editor for writing code. We recommend popular text editors like Visual Studio Code, Sublime Text, or Atom, but any text editor will suffice.",
    },
  },
  {
    question: {
      __html: "5. How much time should I dedicate to the course each week?",
    },
    answer: {
      __html:
        "The time commitment varies depending on your learning pace and prior experience. On average, students spend about 4-6 hours per week on lectures, exercises, and projects. However, you're free to study at your own pace.",
    },
  },
  {
    question: {
      __html: "6. Is there a certificate upon completion of the course?",
    },
    answer: {
      __html:
        "Yes, upon successful completion of all course requirements, including quizzes and projects, you'll receive a certificate of completion from TeacherCool. This certificate can be shared on LinkedIn or added to your resume.",
    },
  },
  {
    question: {
      __html: "7. Can I access the course material after completing it?",
    },
    answer: {
      __html:
        "Yes, you'll have access to the course material even after completing it. You can review the lectures and exercises at any time, which is especially useful for reinforcing your learning or refreshing your skills.",
    },
  },
  {
    question: {
      __html:
        "8. Will I receive support from instructors or peers during the course?",
    },
    answer: {
      __html:
        "Absolutely! Our instructors and teaching assistants are available to answer your questions and provide guidance throughout the course. You'll also have access to a community forum where you can interact with fellow learners, ask questions, and share insights.",
    },
  },
  {
    question: { __html: "9. Can I audit the course for free?" },
    answer: {
      __html:
        "Yes, you can audit the course for free to access the lectures and some course materials. However, auditing does not grant access to graded assignments or the certificate of completion. To receive a certificate, you'll need to enroll in the paid version of the course.",
    },
  },
  {
    question: { __html: "10. How do I enroll in the course?" },
    answer: {
      __html:
        "To enroll in the course, simply visit the course page on TeacherCool, click on the <strong>Login</strong> button, and follow the instructions to complete the enrollment process.",
    },
  },
];
