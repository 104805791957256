import React from "react";
import { useDispatch } from "react-redux";
import { showSignupModal } from "../../store/features/linkedinLoginSlice";

const JoinFreeBtn = (props) => {
  const dispatch = useDispatch();
  const { title, bgColor, color, border, padding, fontSize } = props;
  return (
    <>
      <button
        onClick={() => dispatch(showSignupModal(true))}
        className={`${bgColor} ${color} ${border} ${padding} ${fontSize} rounded-md text-base px-5 py-2 cursor-pointer hover:bg-blue-600 hover:text-white`}
      >
        {title}
      </button>
    </>
  );
};
export default JoinFreeBtn;
