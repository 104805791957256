import React from "react";

const Debugging = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 my-20">
      <div className="md:col-span-8 col-span-1 px-4">
        <h1 className="text-2xl font-semibold font-Roboto text-[#1E1E1E]">
          Mastering Testing and Debugging in React.js
        </h1>
        <p className="text-base font-Roboto text-[#1E1E1E] text-opacity-70 py-2">
          This course is part of the UX Design Professional Certificate. Enroll
          to gain access to the full certificate program.
        </p>
        <div className="flex items-center gap-3">
          <img
            src={process.env.PUBLIC_URL + "/images/student/sign.png"}
            alt="sign"
            className="w-5 h-5"
          />
          <p className="text-[#1E1E1E] text-opacity-80 font-Roboto text-sm py-2">
            Learn new concepts from industry experts
          </p>
        </div>
        <div className="flex items-center gap-3">
          <img
            src={process.env.PUBLIC_URL + "/images/student/sign.png"}
            alt="sign"
            className="w-5 h-5"
          />
          <p className="text-[#1E1E1E] text-opacity-80 font-Roboto text-sm py-2">
            Gain a foundational understanding of a subject or tool
          </p>
        </div>
        <div className="flex items-center gap-3">
          <img
            src={process.env.PUBLIC_URL + "/images/student/sign.png"}
            alt="sign"
            className="w-5 h-5"
          />
          <p className="text-[#1E1E1E] text-opacity-80 font-Roboto text-sm py-2">
            Develop job-relevant skills with hands-on projects
          </p>
        </div>
        <div className="flex items-center gap-3">
          <img
            src={process.env.PUBLIC_URL + "/images/student/sign.png"}
            alt="sign"
            className="w-5 h-5"
          />
          <p className="text-[#1E1E1E] text-opacity-80 font-Roboto text-sm py-2">
            Earn a shareable career certificate from Google
          </p>
        </div>
      </div>
      <div className="md:col-span-4 col-span-1 flex justify-center">
        <img
          src={process.env.PUBLIC_URL + "/images/student/debugging.png"}
          alt="debugging"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
};

export default Debugging;
